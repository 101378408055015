import { getSelectedOptionsForVariantSku } from 'components/consumer/PDP/helpers/pdpOptionUtils';
import { useMemo } from 'react';

const usePDPSelectedOptionsFromVariantSku = ({
  productOptions,
  variantSkuOverride,
}) => {
  const selectedOptionsFromVariantSku = useMemo(() => {
    if (!variantSkuOverride || !productOptions) {
      return null;
    }

    const variantSelectedOptions = getSelectedOptionsForVariantSku(
      variantSkuOverride,
      productOptions
    );

    return variantSelectedOptions;
  }, [productOptions, variantSkuOverride]);

  return selectedOptionsFromVariantSku;
};

export default usePDPSelectedOptionsFromVariantSku;
