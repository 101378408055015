import usePDPOptions from '../context/usePDPOptions';

const DEFAULT_VALUE = {};

const usePdpSelectedOptions = () => {
  const pdpSelectedOptions =
    usePDPOptions()?.data?.selectedOptions ?? DEFAULT_VALUE;

  return pdpSelectedOptions;
};

export default usePdpSelectedOptions;
