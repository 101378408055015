import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ children, className = '', ...otherProps }) =>
  !!children && (
    <span
      className={`text-xs font-bold items-center bg-brand rounded-full text-white inline-flex h-5 justify-center p-1 w-5 ${className}`}
      {...otherProps}
    >
      {children}
    </span>
  );

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Badge;
