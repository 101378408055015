import { useEffect, useMemo } from 'react';
import { isEmpty, isEqual } from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import {
  PDP_READYTOSHIP_TAB_ID,
  PDP_SELECTION_VALIDITY_STATES,
  STOCK_VALUE_STATES,
} from 'commons/constants';
import usePdpSelectedCustomizerTab from 'components/consumer/PDP/state/pdpSelectedCustomizerTab/usePdpSelectedCustomizerTab';
import usePdpSelectedOptions from 'components/consumer/PDP/state/pdpSelectedOptions/usePdpSelectedOptions';
import usePdpSelectedOptionsVariantSku from 'components/consumer/PDP/state/pdpSelectedOptionsVariantSku/usePdpSelectedOptionsVariantSku';
import useSetPdpAllSelectedOptions from 'components/consumer/PDP/state/pdpSelectedOptions/useSetPdpAllSelectedOptions';
import usePdpSlug from 'components/consumer/PDP/state/context/usePdpSlug';
import useUserLocation from 'global-state/userLocation/useUserLocation';
import { getPDPCustomizerURLParams } from '../../components/consumer/PDP/helpers/pdpQueryParamUtils';
import useAllOptions from '../useAllOptions';
import { PRODUCT_LOCAL_STOCK } from '../queries';
import {
  findEnabledSelectedOptions,
  getCombinedOptionSKUs,
  stockSkuToOptions,
  updateSelectedOptionsToFirstInStock,
} from './utils';

const DEFAULT_SELECTED_OPTIONS = {};

const useProductLocalStock = ({
  id,
  slug,
  options,
  hookOptions = {},
  skipAll = false,
  skipPDPState = false,
}) => {
  const {
    hasStockData = false, // When local stock data is passed to the hook
    localQSStock = null, // Local stock data passed to the hook, we don't fetch stock data if this is provided
    pdpHasUserSelectedOptions = false,
    stockDataFromParentLoading = false,
  } = hookOptions;

  const location = useLocation();
  const userLocation = useUserLocation();

  const pdpSelectedOptions = usePdpSelectedOptions();
  const selectedOptions = skipPDPState
    ? DEFAULT_SELECTED_OPTIONS
    : pdpSelectedOptions;

  const pdpSelectedOptionsVariantSku = usePdpSelectedOptionsVariantSku();
  const selectedOptionsVariantSku = skipPDPState
    ? ''
    : pdpSelectedOptionsVariantSku;

  const setPdpAllSelectedOptions = useSetPdpAllSelectedOptions();

  const pdpStateSlug = usePdpSlug();
  const pdpSlug = skipPDPState ? undefined : pdpStateSlug;

  const pdpSelectedCustomizerTab = usePdpSelectedCustomizerTab();

  const { allOptionsValues, loading: allOptionsLoading } = useAllOptions(
    skipAll
  );
  const { loading: localStockLoadingFlag, data, error } = useSuspenseQuery(
    PRODUCT_LOCAL_STOCK,
    {
      variables: {
        id,
        zipCode: userLocation?.zip ? userLocation.zip.toString() : '',
        fromCache: false,
        warehouseId: null, // Warehouse ID not required when calling Caravel for stock
      },
      skip: skipAll || !id || hasStockData,
      ssr: true,
    }
  );

  const localStockLoading = localStockLoadingFlag && !data;

  const isRTSTab = pdpSelectedCustomizerTab === PDP_READYTOSHIP_TAB_ID;

  const { productLocalStock } = data || {};

  const isStockLoading =
    (!hasStockData && (!productLocalStock?.qs_stock || localStockLoading)) ||
    (hasStockData && (!localQSStock || stockDataFromParentLoading)) ||
    !options ||
    (!hasStockData && productLocalStock?.id !== id) ||
    !allOptionsValues;

  const hasQuickshipStock =
    (hasStockData || productLocalStock?.qs_stock?.stock) &&
    localQSStock !== STOCK_VALUE_STATES?.OOS;

  const stockObject = useMemo(() => {
    if (!skipAll && !isStockLoading) {
      if (!hasQuickshipStock) {
        return {
          id: hasStockData ? id : productLocalStock?.id,
          stockOptions: [],
          stockOptionsCombined: [],
          inStockOptionIds: [],
          upholsteryOptionId: null,
          inActiveOptionValueIds: [],
          variantSkuFlashSale: {},
        };
      }
      const productId = id;
      const finalStockData = !hasStockData
        ? productLocalStock?.qs_stock?.stock?.[productId]
        : localQSStock;
      // Check for non-config products
      const stockKeys = !hasStockData
        ? Object.keys(finalStockData || {})
        : Object.keys(localQSStock || {});
      if (stockKeys.length === 1 && stockKeys[0] === '') {
        return {
          id: !hasStockData ? productLocalStock?.id : productId,
          stockOptions: [],
          stockOptionsCombined: [],
          inStockOptionIds: [],
          upholsteryOptionId: null,
          inActiveOptionValueIds: [],
          variantSkuFlashSale: {},
        };
      }
      const deepLinkURLParams = queryString.parse(location?.search);

      const [
        stockOpts,
        stockOptsComb,
        stockIds,
        upholsteryOptionId,
        inActiveOptionValueIds,
      ] = stockSkuToOptions(
        finalStockData,
        options,
        allOptionsValues,
        deepLinkURLParams?.stockVariant
      );

      const variantSkuFlashSale = stockKeys.reduce(
        (acc, stockKey) => ({
          ...acc,
          [stockKey]: finalStockData[stockKey].flashSale,
        }),
        {}
      );

      return {
        id: !hasStockData ? productLocalStock.id : productId,
        stockOptions: stockOpts,
        stockOptionsCombined: stockOptsComb,
        inStockOptionIds: stockIds,
        upholsteryOptionId,
        inActiveOptionValueIds,
        variantSkuFlashSale,
      };
    }
    return {};
  }, [
    allOptionsValues,
    isStockLoading,
    hasQuickshipStock,
    hasStockData,
    id,
    localQSStock,
    location?.search,
    options,
    productLocalStock?.id,
    productLocalStock?.qs_stock?.stock,
    skipAll,
  ]);

  const isSelectedOptionsQuickship = useMemo(() => {
    if (
      !skipAll &&
      !skipPDPState &&
      !isEmpty(selectedOptions) &&
      !!stockObject?.stockOptionsCombined?.length
    ) {
      return Object.keys(stockObject.stockOptionsCombined).some(
        ({ stockOptionIds }) => {
          const matchedOptionCount = stockOptionIds.reduce(
            (matchedOptions, stockOptId) =>
              selectedOptions[stockOptId.optionId] === stockOptId.optionValueId
                ? matchedOptions + 1
                : matchedOptions,
            0
          );

          if (matchedOptionCount === Object.keys(selectedOptions).length) {
            return true;
          }
          return false;
        }
      );
    }
    return false;
  }, [
    selectedOptions,
    skipAll,
    skipPDPState,
    stockObject?.stockOptionsCombined,
  ]);

  const selectedOptionsStockQty = useMemo(() => {
    if (!skipAll) {
      if (!isStockLoading) {
        if (!hasQuickshipStock) {
          return 0;
        }
        const productId = id;
        const finalStockData = !hasStockData
          ? productLocalStock?.qs_stock.stock[productId]
          : localQSStock;
        // Check for non-config products
        const stockKeys = !hasStockData
          ? Object.keys(finalStockData || {})
          : Object.keys(localQSStock || {});
        if (stockKeys.length === 1 && stockKeys[0] === '') {
          const stockData = finalStockData[''];
          return stockData.stock;
        }
      }
      if (
        !skipPDPState &&
        !isEmpty(selectedOptions) &&
        !!stockObject?.stockOptionsCombined?.length
      ) {
        for (const stockOpt of stockObject.stockOptionsCombined) {
          const { stockOptionIds, stockQty } = stockOpt;
          let matchedOptions = 0;

          stockOptionIds.forEach(stockOptId => {
            if (
              selectedOptions[stockOptId.optionId] === stockOptId.optionValueId
            ) {
              matchedOptions++;
            }
          });

          if (matchedOptions === Object.keys(selectedOptions).length) {
            return stockQty;
          }
        }
      }
    }
    return null;
  }, [
    isStockLoading,
    hasQuickshipStock,
    hasStockData,
    id,
    localQSStock,
    productLocalStock?.qs_stock?.stock,
    selectedOptions,
    skipAll,
    skipPDPState,
    stockObject?.stockOptionsCombined,
  ]);

  const stockOptionsDisabledStatus = useMemo(() => {
    if (
      !skipAll &&
      !skipPDPState &&
      !!selectedOptionsVariantSku &&
      !!stockObject?.stockOptionsCombined?.length &&
      isRTSTab
    ) {
      // Set disabled status
      const updatedDisabledState = getCombinedOptionSKUs(
        selectedOptionsVariantSku,
        stockObject?.stockOptionsCombined,
      );
      return updatedDisabledState;
    }
    return [];
  }, [
    isRTSTab,
    selectedOptionsVariantSku,
    skipAll,
    skipPDPState,
    stockObject?.stockOptionsCombined,
  ]);

  const selectedOptionsHasInactive = useMemo(() => {
    if (
      !skipAll &&
      !skipPDPState &&
      (!!pdpHasUserSelectedOptions || !getPDPCustomizerURLParams(location)) &&
      !isEmpty(selectedOptions) &&
      !!stockObject?.stockOptionsCombined?.length &&
      isRTSTab &&
      !!stockObject?.stockOptions?.length
    ) {
      const {
        stockOptions,
        stockOptionsCombined,
        inActiveOptionValueIds,
        upholsteryOptionId,
      } = stockObject;

      const [
        updatedSelectedOptions,
        hasInactive,
      ] = updateSelectedOptionsToFirstInStock({
        stockOptions,
        stockOptionsCombined,
        inActiveOptionValueIds,
      });

      if (
        updatedSelectedOptions &&
        !isEqual(updatedSelectedOptions, selectedOptions) &&
        hasInactive
      ) {
        return true;
      }

      if (stockObject?.inActiveOptionValueIds?.length) {
        // Check inactive Ids against selection
        return Object.keys(selectedOptions).some(optId => {
          if (
            stockObject?.inActiveOptionValueIds.includes(selectedOptions[optId])
          ) {
            return true;
          }
          return false;
        });
      }

      if (
        isRTSTab &&
        upholsteryOptionId &&
        !isEmpty(stockOptionsDisabledStatus) &&
        !!stockOptions?.length &&
        !isEqual(updatedSelectedOptions, selectedOptions) &&
        hasInactive
      ) {
        return true;
      }
    }
    return false;
  }, [
    isRTSTab,
    location,
    pdpHasUserSelectedOptions,
    selectedOptions,
    skipAll,
    skipPDPState,
    stockObject,
    stockOptionsDisabledStatus,
  ]);

  const selectionValidity = useMemo(() => {
    if (!skipAll) {
      const shouldCheckForDisabledOptions =
        !!isRTSTab && !isEmpty(stockOptionsDisabledStatus) && !skipPDPState;

      if (
        shouldCheckForDisabledOptions &&
        Object.keys(selectedOptions).some(
          selectionKey =>
            stockOptionsDisabledStatus[selectedOptions[selectionKey]] &&
            !selectedOptionsHasInactive
        )
      ) {
        return PDP_SELECTION_VALIDITY_STATES.INVALID;
      }

      if (
        shouldCheckForDisabledOptions &&
        Object.keys(selectedOptions).some(
          selectionKey =>
            stockOptionsDisabledStatus[selectedOptions[selectionKey]] &&
            selectedOptionsHasInactive
        )
      ) {
        return PDP_SELECTION_VALIDITY_STATES.INACTIVE;
      }
    }

    return '';
  }, [
    isRTSTab,
    selectedOptions,
    selectedOptionsHasInactive,
    skipAll,
    skipPDPState,
    stockOptionsDisabledStatus,
  ]);

  const updateSelectedOptionsToEnabledOptions = (onlyActive = false) => {
    const {
      stockOptions,
      stockOptionsCombined,
      upholsteryOptionId,
      inActiveOptionValueIds,
    } = stockObject;

    if (
      skipAll ||
      skipPDPState ||
      isEmpty(stockOptionsDisabledStatus) ||
      isEmpty(selectedOptions) ||
      !stockOptions?.length ||
      !stockOptionsCombined?.length
    ) {
      return;
    }

    const [
      updatedSelectedOptions,
      updatedSelectedOptionsFull,
    ] = findEnabledSelectedOptions({
      selectedOptions,
      stockOptionsDisabledStatus,
      stockOptionsCombined,
      stockOptions,
      upholsteryOptionId,
      inActiveOptionValueIds,
      onlyActive,
    });

    if (!isEqual(updatedSelectedOptions, selectedOptions)) {
      setPdpAllSelectedOptions(updatedSelectedOptionsFull);
    }
  };

  const updateSelectionToFirstStock = () => {
    if (skipAll || skipPDPState) {
      return;
    }

    const {
      stockOptions,
      stockOptionsCombined,
      upholsteryOptionId,
      inActiveOptionValueIds,
    } = stockObject;

    const [
      updatedSelectedOptions,
      updatedSelectedOptionsFull,
    ] = updateSelectedOptionsToFirstInStock({
      stockOptions,
      stockOptionsCombined,
      upholsteryOptionId,
      inActiveOptionValueIds,
    });

    if (
      updatedSelectedOptions &&
      !isEqual(updatedSelectedOptions, selectedOptions)
    ) {
      // reset pdp selectedOptions when pdpSlug matches this hook slug
      if (slug === pdpSlug) {
        setPdpAllSelectedOptions(updatedSelectedOptionsFull);
      }
    }
  };

  useEffect(() => {
    if (
      skipAll ||
      skipPDPState ||
      (!pdpHasUserSelectedOptions && getPDPCustomizerURLParams(location))
    ) {
      return;
    }

    if (!isRTSTab || !stockObject?.stockOptions?.length) {
      // Switching out of RTS reset inactive selection
      if (!isRTSTab && selectedOptionsHasInactive) {
        updateSelectedOptionsToEnabledOptions(true);
      }
      return;
    }
    updateSelectionToFirstStock();
    // TODO: Refactor this code to allow all dependencies to be included within
    // the array, to prevent bugs without causing unnecessary re-renders in
    // SSR. Should be quicker to do this after migrating to Next.js.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpSelectedCustomizerTab, stockObject?.stockOptions]);

  useEffect(() => {
    if (
      skipAll ||
      skipPDPState ||
      isEmpty(selectedOptions) ||
      !stockObject?.stockOptionsCombined?.length
    ) {
      return;
    }

    const { upholsteryOptionId } = stockObject;

    // In RTS tab Check if primary option was changed, if so reset selection to updatedSelectedOptions
    if (isRTSTab && upholsteryOptionId) {
      updateSelectedOptionsToEnabledOptions();
    }
    // TODO: Refactor this code to allow all dependencies to be included within
    // the array, to prevent bugs without causing unnecessary re-renders in
    // SSR. Should be quicker to do this after migrating to Next.js.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, stockObject?.stockOptionsCombined]);

  const stock = useMemo(() => {
    if (!skipAll && isEmpty(stockObject)) {
      return {
        showReadyToShip: false,
        isSelectedOptionsQuickship,
        selectedOptionsStockQty,
        selectionValidity,
        stockOptionsDisabledStatus,
      };
    }
    return {
      idForStock: stockObject.id,
      showReadyToShip: !!stockObject?.stockOptions?.length,
      stockOptions: stockObject?.stockOptions,
      stockOptionsCombined: stockObject?.stockOptionsCombined,
      inStockOptionIds: stockObject?.inStockOptionIds,
      isSelectedOptionsQuickship,
      selectedOptionsStockQty,
      selectionValidity,
      stockOptionsDisabledStatus,
      variantSkuFlashSale: stockObject.variantSkuFlashSale,
    };
  }, [
    skipAll,
    stockObject,
    isSelectedOptionsQuickship,
    selectedOptionsStockQty,
    selectionValidity,
    stockOptionsDisabledStatus,
  ]);

  return {
    loading: localStockLoading || allOptionsLoading || isStockLoading,
    error,
    ...stock,
  };
};

export default useProductLocalStock;
