import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProductCardOnDisplayInShowroom from 'components/consumer/product-card/ProductCardOnDisplayInShowroom';
import Link from 'components/consumer/Link';
import avo from 'analytics/avo';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useProductCardContext from 'data-hooks/useProductCardContext';
import ProductCardLabelUI from 'components/consumer/product-card/ProductCardLabel/ProductCardLabelUI';
import ProductCardNameUI from 'components/consumer/product-card/ProductCardNameUI';
import ProductCardPriceUI from 'components/consumer/product-card/ProductCardPrice/ProductCardPriceUI';
import IconQuickship from 'components/icons/iconQuickship';
import { colorGrayLight2 } from 'styles/layout/colors';
import usePDPClearanceData from 'data-hooks/useClearanceData/usePDPClearanceData';
import ProductCardSwatchesNewUI from 'components/consumer/product-card/ProductCardSwatches/ProductCardSwatchesNewUI';
import ProductCardOptionsUI from 'components/consumer/product-card/ProductCardOptions/ProductCardOptionsUI';
import TabletAndDesktopOnly from 'components/consumer/TabletAndDesktopOnly';
import useCloseSearch from 'global-state/isSearchOpen/useCloseSearch';
import ProductCardPhotoSquare from './components/ProductCardPhotoSquare';
import ProductCardFavoriteButton from './components/ProductCardFavoriteButton';

const ProductCardStaticUI = ({
  analyticsComponentName,
  hidden,
  enableMobileCarousel,
  onClick,
  isHotspotCard = false,
  layout,
}) => {
  const history = useHistory();

  const {
    id,
    image,
    isClearance,
    isOutOfStock,
    label = '',
    loading,
    minPrice,
    name,
    onDisplayInShowroomSlug,
    originalPrice,
    path,
    position,
    price,
    productFamily,
    productLabel,
    productType,
    quickship,
    showAttributes,
    showProductLabel,
    showShowroomInfo,
    showStockInfo,
    showSwatches,
    sku,
    upholsteryThumbnail,
    variantsToShow,
  } = useProductCardContext();

  const currentPage = useUserCurrentPageType();
  const { data: pdpClearanceData } = usePDPClearanceData();
  const closeSearch = useCloseSearch();

  const trackProductClicked = useCallback(() => {
    const clearanceDataKeys = Object.keys(
      pdpClearanceData?.optionsClearanceData ?? {}
    );
    avo.productClicked({
      category: productType,
      component: analyticsComponentName,
      contentIds: [sku.split('-')[0]],
      currency: 'USD',
      fullPrice: parseFloat(originalPrice),
      isClearance,
      location: currentPage,
      name,
      position,
      price: parseFloat(price),
      productFamily,
      productId: id,
      quantity: 1,
      quickship,
      sku,
      variant: label,
      isFlashSale: clearanceDataKeys.includes(sku),
    });
  }, [
    analyticsComponentName,
    currentPage,
    id,
    isClearance,
    label,
    name,
    originalPrice,
    position,
    price,
    productFamily,
    productType,
    quickship,
    sku,
    pdpClearanceData,
  ]);

  const onProductClick = useCallback(
    event => {
      trackProductClicked(event);

      if (event.ctrlKey || event.metaKey) {
        // If Ctrl key (Windows) or Command key (Mac) is pressed, use the
        // default link behavior instead of using history.push(), so that the
        // link opens in a new tab as the user expects.
        return;
      }

      event.preventDefault();
      onClick?.();
      closeSearch();
      if (path.startsWith('/modular/') || path.startsWith('/modulars/')) {
        // force reload modular page to handle marxent performance issues
        // https://stitchindustries.atlassian.net/browse/CON-6483
        window.location.href = path;
      } else {
        history.push(path, {
          image,
          minPrice,
          name,
          originalPrice,
          price,
        });
      }
    },
    [
      history,
      image,
      minPrice,
      name,
      originalPrice,
      path,
      price,
      trackProductClicked,
      onClick,
      closeSearch,
    ]
  );

  const shortLabel = label.split(',')?.[0] ?? '';
  const otherLabels = label
    .split(',')
    .slice(1)
    .map(item => item.trim());
  return (
    <div
      className={classNames(
        'rounded-sm relative text-gray [transition:all_0.25s_ease-in-out] pb-2 md:pb-0',
        { '!rounded-lg !p-2.5': isHotspotCard },
        { hidden }
      )}
    >
      <Link
        onClick={onProductClick}
        to={path}
        aria-label={name}
        className="block hover:!no-underline group/product-card"
      >
        <ProductCardPhotoSquare enableMobileCarousel={enableMobileCarousel} />

        <div className="flex flex-col xl:flex-row xl:justify-between w-full px-[10px] md:px-0 pb-2 relative">
          <div className="h-[4.6rem]">
            <ProductCardNameUI name={name} />

            <ProductCardPriceUI
              hasDiscount={
                !!originalPrice && !!price && originalPrice !== price
              }
              loading={loading}
              minPrice={minPrice}
              originalPrice={originalPrice ?? price}
              salePrice={price}
            />
          </div>

          <div className="xl:mt-4">
            {showStockInfo && !!quickship && (
              <div className="text-xs flex items-center text-gray-light1 mt-1 gap-2">
                <IconQuickship color={colorGrayLight2} width={18} height={12} />

                <span>Ready to ship</span>
              </div>
            )}

            {showStockInfo && !!isOutOfStock && (
              <div className="text-xs text-red mt-1">Out of stock</div>
            )}
          </div>
        </div>

        {showAttributes && (!!shortLabel || !!otherLabels.length) && (
          <ProductCardOptionsUI
            fabricSelectedOption={{
              label: shortLabel,
              image: upholsteryThumbnail,
            }}
            productOptions={otherLabels}
          />
        )}

        {showProductLabel && !!productLabel && (
          <ProductCardLabelUI label={productLabel} />
        )}

        {/* Do not show favorite button on mini layout */}
        {layout !== 'mini' && (
          <div className="absolute top-5 right-5 group-hover:opacity-100">
            <ProductCardFavoriteButton />
          </div>
        )}
      </Link>

      {showSwatches && variantsToShow?.length >= 2 && (
        <TabletAndDesktopOnly>
          <div className="max-[767px]:hidden">
            <ProductCardSwatchesNewUI onProductClick={onProductClick} />
          </div>
        </TabletAndDesktopOnly>
      )}

      {showShowroomInfo && !!onDisplayInShowroomSlug && (
        <div className="mt-2">
          <ProductCardOnDisplayInShowroom />
        </div>
      )}
    </div>
  );
};

ProductCardStaticUI.propTypes = {
  analyticsComponentName: PropTypes.string,
  hidden: PropTypes.bool,
  isHotspotCard: PropTypes.bool,
  enableMobileCarousel: PropTypes.bool,
  onClick: PropTypes.func,
  layout: PropTypes.oneOf([
    'full',
    'mini',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ]),
};

export default ProductCardStaticUI;
