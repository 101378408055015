import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PDPAlertBarIcon from './PDPAlertBarIcon';

const PDPAlertBar = ({ children, type = 'info', className }) => (
  <div
    className={classNames('py-4 flex items-center mt-8', {
      'bg-yellow-light': type === 'info',
      'bg-red-light': type === 'error',
      [className]: !!className,
    })}
  >
    <div className="px-6 flex justify-center items-center">
      <PDPAlertBarIcon type={type} />
    </div>

    <div className="text-base pr-3 [&>a]:font-bold [&>a]:underline">
      {children}
    </div>
  </div>
);

PDPAlertBar.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['info', 'error']),
  className: PropTypes.string,
};

export default PDPAlertBar;
