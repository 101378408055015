import React from 'react';
import PropTypes from 'prop-types';

import IconQuality from 'components/icons/iconQuality';

const SuccessMessage = ({
  onClick,
  text,
  bodyText,
  linkText,
  orderSwatchesAsFirstLevel,
}) => (
  <div className="items-center bg-brand text-white flex flex-col h-screen justify-center p-12 text-center md:absolute md:left-0 md:top-0 md:right-0 md:bottom-0">
    <div className="mb-4">
      <IconQuality />
    </div>

    <p className="text-xl font-bold mb-4">{text}</p>
    <p className="text-sm text-white mb-12">{bodyText}</p>

    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <span
      className="text-base font-bold hover:cursor-pointer"
      onClick={() => onClick(orderSwatchesAsFirstLevel)}
    >
      {linkText}
    </span>
  </div>
);

SuccessMessage.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  bodyText: PropTypes.string,
  linkText: PropTypes.string,
  orderSwatchesAsFirstLevel: PropTypes.func,
};

export default SuccessMessage;
