import { cloneDeep } from 'lodash';

import {
  PDP_READYTOSHIP_TAB_ID,
  UPHOLSTERY_OPTION_IDS,
} from 'commons/constants';

const getUpholsteryOption = (options = []) =>
  options?.find(({ id }) => UPHOLSTERY_OPTION_IDS.includes(id));

// Get all options for product in the selected customizer tab
const usePDPOptionsForCustomizerTab = ({
  productOptions,
  selectedCustomizerTab,
  stock,
}) => {
  if (
    selectedCustomizerTab === PDP_READYTOSHIP_TAB_ID &&
    stock?.stockOptions?.length
  ) {
    const stockOptions = cloneDeep(stock?.stockOptions);
    const upholsteryOption = getUpholsteryOption(stockOptions);

    if (upholsteryOption) {
      upholsteryOption.values = upholsteryOption?.values.sort((a, b) =>
        a.value.localeCompare(b.value)
      );
    }

    return stockOptions;
  }

  return productOptions || null;
};

export default usePDPOptionsForCustomizerTab;
