import React from 'react';
import PropTypes from 'prop-types';

import ProductCardImage from 'components/consumer/product-card/ProductCardPhoto/ProductCardImage';
import ProductCardImageComingSoon from './ProductCardImageComingSoon';

const ProductCardConfigImage = ({ image, isFlipped = false }) => {
  if (!image) {
    return <ProductCardImageComingSoon />;
  }

  return (
    <ProductCardImage
      customImg={image}
      displayOptionImages={false}
      hasPreLoad={false}
      imageClassName="!object-contain max-w-[80%] max-h-[70%] m-[1.25rem]"
      imageLoaded={false}
      layout="category"
      productCategory=""
      productImageOptionTypeId={null}
      setLazy={false}
      trimCustomImage
      isFlipped={isFlipped}
    />
  );
};

ProductCardConfigImage.propTypes = {
  image: PropTypes.string,
  isFlipped: PropTypes.bool,
};

export default ProductCardConfigImage;
