export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const toTitleCase = str =>
  str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const normalize = ({ data, key = 'id' }) => {
  const obj = {};
  const ids = [];
  if (data && data.length) {
    data.forEach(element => {
      obj[element[key]] = element;
      ids.push(element[key]);
    });
  }
  return {
    data: obj,
    ids,
  };
};

export const groupByArrWithNormalize = ({
  data,
  groupKey = 'id',
  itemKey = 'id',
  sortIds = false,
}) => {
  const obj = {};
  let ids = [];
  data.forEach(element => {
    if (!obj[element[groupKey]]) {
      ids.push(element[groupKey]);
      obj[element[groupKey]] = {
        [element[itemKey]]: {
          ...element,
        },
      };
    } else {
      obj[element[groupKey]] = {
        ...obj[element[groupKey]],
        [element[itemKey]]: {
          ...element,
        },
      };
    }
  });
  if (sortIds) {
    ids = ids.sort();
  }
  return {
    data: obj,
    ids,
  };
};

export const groupByObjWithNormalize = ({
  data,
  groupKey = 'id',
  itemKey = 'id',
  sortIds = false,
}) => {
  const obj = {};
  let ids = [];
  Object.values(data).forEach(element => {
    if (!obj[element[groupKey]]) {
      ids.push(element[groupKey]);
      obj[element[groupKey]] = {
        [element[itemKey]]: {
          ...element,
        },
      };
    } else {
      obj[element[groupKey]] = {
        ...obj[element[groupKey]],
        [element[itemKey]]: {
          ...element,
        },
      };
    }
  });

  if (sortIds) {
    ids = ids.sort();
  }

  return {
    data: obj,
    ids,
  };
};

export const flattenObj = (target, opts = {}) => {
  const delimiter = opts.delimiter || '.';
  const maxDepth = opts.maxDepth || 10;
  const output = {};

  const step = (object, prev, currentDepth = 1) => {
    Object.keys(object).forEach(key => {
      const value = object[key];
      const isArray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isObject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev ? prev + delimiter + key : key;

      if (
        !isArray &&
        isObject &&
        Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)
      ) {
        return step(value, newKey, currentDepth + 1);
      }

      output[newKey] = value;
      return {};
    });
  };

  step(target);

  return output;
};
