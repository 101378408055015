import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { COLOR_CODES_FABRIC } from 'commons/constants';
import TogglePillGroup from 'components/consumer/TogglePillGroup';
import useKeyboardControlForTabs from 'hooks/useKeyboardControlForTabs';
import VisuallyHidden from 'components/consumer/VisuallyHidden';

// Converts COLOR_CODES_FABRIC into a flat object, mapping color codes to names
const colorCodesMap = COLOR_CODES_FABRIC.reduce(
  (acc, item) => ({
    ...acc,
    ...item,
  }),
  {}
);

const ColorsFilter = ({
  bindParentName = false,
  colors = [],
  disabledColors = {},
  loading,
  onSelectColor,
  selectedColors,
  showAsTwoRows = false,
  variant,
  showRowInMobile = false,
  showMobileColorFilter = false,
}) => {
  const buttonsWrapperRef = useRef(null);
  useKeyboardControlForTabs(buttonsWrapperRef);

  const styles = {
    colorFabricItem:
      'bg-transparent rounded-[50%] border border-gray-light6 cursor-pointer block flex-1 h-5 list-none mr-1 min-w-[20px] focus-visible:outline-offset-[3px]',
    colorFabricItemActive: 'outline-0 border-white shadow-[0_0_0_1px_#016a78]',
    fabricColorWhite: 'shadow-[inset_0_0_0_1px_#e5e5e5]',
  };

  const cx = classNames.bind(styles);

  return (
    <div className="ColorsFilter__wrapper" ref={buttonsWrapperRef}>
      <VisuallyHidden>
        Clicking on the following button will update the content below.
      </VisuallyHidden>

      <div
        className={classNames({
          'md:hidden': showRowInMobile === false,
          hidden: showRowInMobile,
        })}
      >
        <TogglePillGroup
          items={colors
            .filter(color => !!color)
            .map(color => {
              const lColor = color && color.toLowerCase();
              const backgroundColor = colorCodesMap[lColor] || '';
              return {
                ariaLabel: color,
                label: (
                  <span className="items-center flex flex-row" key={lColor}>
                    <span
                      style={{
                        backgroundColor,
                      }}
                      className={cx(
                        'colorFabricItem',
                        'h-[21px] -my-[3px] -ml-[7px] min-w-[21px]',
                        {
                          'max-[767px]:!w-10 max-[767px]:!h-10': showMobileColorFilter,
                          colorFabricItemActive: !!selectedColors?.[color],
                        }
                      )}
                    />
                    <span className="-mr-0.5 relative -top-px leading-[0] capitalize">
                      {color}
                    </span>
                  </span>
                ),
                id: color,
                isDisabled: disabledColors[color],
                isSelected: !!selectedColors[color],
                onClick: () =>
                  bindParentName
                    ? onSelectColor('color', color)
                    : onSelectColor(color),
              };
            })}
          variant={variant}
        />
      </div>

      <ul
        className={cx(
          'flex flex-wrap w-full overflow-auto py-2 px-1 m-0 list-none',
          {
            'grid grid-cols-[repeat(6,_1fr)] py-1 px-0.5': !!showAsTwoRows,
            'max-[767px]:hidden': !showRowInMobile,
            'pl-9 overflow-x-scroll !flex-nowrap': showRowInMobile,
          }
        )}
      >
        {colors.map(color => {
          const lColor = color && color.toLowerCase();
          const backgroundColor = colorCodesMap[lColor] || '';
          const isDisabled = !!disabledColors[lColor];

          return (
            <li className="flex list-none p-0 m-0 group" key={lColor}>
              <button
                type="button"
                key={color}
                onClick={!isDisabled ? () => onSelectColor(color) : undefined}
                style={{
                  backgroundColor,
                }}
                className={cx('colorFabricItem', {
                  colorFabricItemActive: !!selectedColors[color],
                  'opacity-[0.1] cursor-not-allowed': isDisabled,
                  'max-[767px]:!w-10 max-[767px]:!h-10': showMobileColorFilter,
                })}
                aria-label={color}
              >
                {loading && (
                  <span className="w-full h-full rounded-[50%] inline-block" />
                )}
                {!!selectedColors[color] && (
                  <VisuallyHidden>- selected</VisuallyHidden>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ColorsFilter.propTypes = {
  bindParentName: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  disabledColors: PropTypes.object,
  loading: PropTypes.bool,
  onSelectColor: PropTypes.func,
  selectedColors: PropTypes.object,
  showAsTwoRows: PropTypes.bool,
  variant: PropTypes.oneOf(['ShopByHeaderSection']),
  showRowInMobile: PropTypes.bool,
  showMobileColorFilter: PropTypes.bool,
};

export default ColorsFilter;
