import React from 'react';
import PropTypes from 'prop-types';

import IconErrorTriangle from 'components/icons/IconErrorTriangle';
import IconInfoNew from 'components/icons/IconInfoNew';
import { colorRedDark, colorGrayOverlay } from 'styles/layout/colors';

const PDPAlertBarIcon = ({ type = 'info' }) => {
  if (type === 'error') {
    return <IconErrorTriangle color={colorRedDark} />;
  }

  return <IconInfoNew color={colorGrayOverlay} width={21} height={21} />;
};

PDPAlertBarIcon.propTypes = {
  type: PropTypes.oneOf(['info', 'error']),
};

export default PDPAlertBarIcon;
