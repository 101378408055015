import { gql } from '@apollo/client';

import useSuspenseQuery from 'commons/useSuspenseQuery';

export const GET_PROMOTION_CONTENT_QUERY = gql`
  query getPromotionContent($entryID: String, $carouselEntryID: String) {
    getPromotionContent(entryID: $entryID, carouselEntryID: $carouselEntryID) {
      id
      title
      alternateTitle
      topBannerText
      body
      alternateBody
      disclaimer
      icon
      backgroundImage
      backgroundColor
      heroTextBackgroundColor
      mainTextColor
      heroFancyText
      heroFancyTextColor
      heroLogomark
      endDate
      displayFlashSale

      mediaAssetUltraWide
      mediaAssetExtraWide
      mediaAssetWide
      mediaAssetSquare

      linkUrl

      ctaText
      ctaStyle
      ctaTextColor
      ctaLinkColor
      ctaBackgroundColor

      topBannerTextColor
      topBannerBackgroundColor
      topBannerBackgroundImage

      secondaryBannerText

      saleDropdownLeftSideText
      saleDropdownLinkUrl
      saleDropdownMedia
      saleDropdownAuxPromoText
      saleDropdownAuxPromoUrl

      promotionCarousel {
        id
        slides {
          fancyText
          fancyTextColor
          title
          disclaimer
          mainTextColor
          backgroundImage
          mediaAssetWide
          mediaAssetSquare
          mediaAssetExtraWide
          mediaAssetUltraWide
          ctaText
          ctaStyle
          ctaTextColor
          ctaBackgroundColor
          linkUrl
        }
      }
    }
  }
`;

export default function useContentfulPromotionContent(
  entryID,
  carouselEntryID = ''
) {
  const { loading, data } = useSuspenseQuery(GET_PROMOTION_CONTENT_QUERY, {
    variables: { entryID, carouselEntryID },
  });
  return {
    loading,
    promotionData: (data || {}).getPromotionContent,
  };
}
