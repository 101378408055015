import { useSetAtom } from 'jotai';

import usePdpSelectedOptionsExtendedAtom from './usePdpSelectedOptionsExtendedAtom';

const useSetPdpSelectedOptionsExtended = () => {
  const pdpSelectedOptionsExtendedAtom = usePdpSelectedOptionsExtendedAtom();

  const setPdpSelectedOptionsExtended = useSetAtom(
    pdpSelectedOptionsExtendedAtom
  );

  return setPdpSelectedOptionsExtended;
};

export default useSetPdpSelectedOptionsExtended;
