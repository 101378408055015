import React from 'react';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/consumer/InfoTooltip';

const BrandTooltip = ({
  children,
  closeButtonClassName,
  iconClassName = '',
  openOnHover = false,
  showCloseButton = true,
  title,
  variant,
  ...otherProps
}) => (
  <InfoTooltip
    closeButtonClassName={closeButtonClassName}
    iconClassName={iconClassName}
    modifier="brand-fade"
    openOnHover={openOnHover}
    parentTitle={title}
    showCloseButton={showCloseButton}
    variant={variant}
    withArrow
    {...otherProps}
  >
    {!!children && <div className="text-xs m-0 normal-case">{children}</div>}
  </InfoTooltip>
);

BrandTooltip.propTypes = {
  children: PropTypes.node,
  closeButtonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  mode: PropTypes.oneOf(['quickview', 'pdp']),
  openOnHover: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  title: PropTypes.node,
  variant: PropTypes.string,
};

export default BrandTooltip;
