// Map the "layout" prop to booleans that are more readable

const getProductCardLayoutFlags = layout => ({
  showAttributes: ['minimal-with-attributes'].includes(layout),
  showProductLabel: [
    'full',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ].includes(layout),
  showShowroomInfo: ['full', 'minimal-with-showroom'].includes(layout),
  showStockInfo: [
    'full',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ].includes(layout),
  showSwatches: ['full'].includes(layout),
});

export default getProductCardLayoutFlags;
