import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
  getOptionsOrder,
  isUpholsteryOption,
} from 'components/consumer/PDP/helpers/pdpOptionUtils';
import { PDP_CUSTOMIZE_TAB_ID } from 'commons/constants';
import { removeDeepLinkURLParams } from 'components/consumer/PDP/helpers/pdpQueryParamUtils';
import useAllProtectionPlanTypes from 'data-hooks/useAllProtectionPlanTypes';
import getSelectedOptionsFromExtendedFormat from '../../../state/helpers/getSelectedOptionsFromExtendedFormat';
import getVariantSkuForSelectedOptionsExtended from '../../../state/helpers/getVariantSkuForSelectedOptionsExtended';
import usePDPGetOptionsForProduct from './usePDPGetProductOptions';
import usePDPLocalStock from './usePDPLocalStock';
import usePDPOptionsForCustomizerTab from './usePDPOptionsForCustomizerTab';
import usePDPDefaultOptionValues from './usePDPDefaultOptionValues';
import usePDPInitialOptionsFromQueryParams from './usePDPInitialOptionsFromQueryParams';
import usePDPSelectedOptionsFromVariantSku from './usePDPSelectedOptionsFromVariantSku';
import mergeSelectedOptions from './mergeSelectedOptions';
import getExtendedSelectedOptions from './getExtendedSelectedOptions';
import usePDPOptionsOrientationIds from './usePDPOptionsOrientationIds';
import usePDPOptionsHasSingleOption from './usePDPOptionsHasSingleOption';
import usePDPUpdateUrlQueryParams from './usePDPUpdateQueryParams';

const usePDPCreateProductOptions = (
  { id, shouldUpdateQueryParam, slug, pdpVariantSkuOverride = null } = {},
  { skip = false } = {}
) => {
  // 1. Get all options for current product
  const {
    data: productOptions,
    loading: productOptionsLoading,
    error: productOptionsError,
  } = usePDPGetOptionsForProduct({ id, slug }, { skip });

  // Derive the options order from the product options
  const pdpOptionsOrder = useMemo(() => getOptionsOrder(productOptions), [
    productOptions,
  ]);

  // 2. Get stock data for this product
  const stock = usePDPLocalStock({ id, slug, productOptions }, { skip });

  // 3. Get all options for product in the selected customizer tab
  const pdpSelectedCustomizerTabAtom = useMemo(
    () => atom(PDP_CUSTOMIZE_TAB_ID),
    []
  );

  const selectedCustomizerTab = useAtomValue(pdpSelectedCustomizerTabAtom);

  const allProductOptionsForSelectedTab = usePDPOptionsForCustomizerTab({
    productOptions,
    selectedCustomizerTab,
    stock,
  });

  // 4. Get default values for options, in extended selected options format
  const defaultSelectedOptionsExtended = usePDPDefaultOptionValues({
    id,
    productOptions: allProductOptionsForSelectedTab,
  });

  // 5. Get option values specified via the initial query params, in simple
  //    selected options format
  const selectedOptionsFromURLOverride = usePDPInitialOptionsFromQueryParams({
    productOptions,
  });

  // 6. Get option values specified via pdpVariantSkuOverride prop (used by
  //    QuickViewModal), in simple selected options format
  const selectedOptionsWithVariantOverride = usePDPSelectedOptionsFromVariantSku(
    {
      productOptions: allProductOptionsForSelectedTab,
      variantSkuOverride: pdpVariantSkuOverride,
    }
  );

  // 7. User-selected option values, in extended selected options format
  // Note: the initial values for the user-selected options will use the values
  // from router state if specified, to support seamless navigation between
  // PDPs via the Available In Sizes widget
  // TODO: Set default value to the options set in the router state - CON-6204

  const userSelectedOptionsExtendedAtom = useMemo(() => atom({}), []);

  const [userSelectedOptionsExtended, setUserSelectedOptionsExtended] = useAtom(
    userSelectedOptionsExtendedAtom
  );

  const userSelectedOptions = useMemo(
    () => getSelectedOptionsFromExtendedFormat(userSelectedOptionsExtended),
    [userSelectedOptionsExtended]
  );

  // 8. Merge all sources of selected option values into a single object, in
  //    extended selected options format
  const defaultSelectedOptions = useMemo(
    () =>
      getSelectedOptionsFromExtendedFormat(
        defaultSelectedOptionsExtended || {}
      ),
    [defaultSelectedOptionsExtended]
  );

  const selectedOptions = useMemo(() => {
    const mergedOptions = mergeSelectedOptions({
      productOptions: allProductOptionsForSelectedTab,
      selectedOptionsArray: [
        defaultSelectedOptions,
        selectedOptionsFromURLOverride,
        selectedOptionsWithVariantOverride,
        userSelectedOptions,
      ],
    });

    return mergedOptions;
  }, [
    allProductOptionsForSelectedTab,
    defaultSelectedOptions,
    selectedOptionsFromURLOverride,
    selectedOptionsWithVariantOverride,
    userSelectedOptions,
  ]);

  const selectedOptionsExtended = useMemo(
    () =>
      getExtendedSelectedOptions({
        productOptions: allProductOptionsForSelectedTab,
        selectedOptions,
      }),
    [allProductOptionsForSelectedTab, selectedOptions]
  );

  // 9. Derive the final variant SKU from the selectedOptionsExtended and
  //    optionsOrder data

  // Create PDP atoms with static initial values
  const pdpHasUserSelectedOptionsAtom = useMemo(() => atom(false), []);
  const pdpLastSelectedOptionIdAtom = useMemo(() => atom(''), []);
  const pdpLastSelectionFromAtom = useMemo(() => atom(null), []);
  const pdpLastHoveredOptionsExtendedAtom = useMemo(() => atom({}), []);

  const [pdpHasUserSelectedOptions, setPdpHasUserSelectedOptions] = useAtom(
    pdpHasUserSelectedOptionsAtom
  );

  const setPdpLastSelectedOption = useSetAtom(pdpLastSelectedOptionIdAtom);
  const setPdpLastSelectionFrom = useSetAtom(pdpLastSelectionFromAtom);

  const setPdpSelectedOption = useCallback(
    (
      optionId,
      optionValue,
      updatedPdpLastSelectionFrom,
      isSelectedByUser = true
    ) => {
      setUserSelectedOptionsExtended(currentPdpSelectedOptionsExtended => ({
        ...currentPdpSelectedOptionsExtended,
        [optionId]: {
          value: {
            id: optionValue.id,
            sku: optionValue.sku,
            value: optionValue.value,
          },
        },
      }));

      setPdpLastSelectedOption(optionId);
      setPdpHasUserSelectedOptions(isSelectedByUser);
      setPdpLastSelectionFrom(updatedPdpLastSelectionFrom);
    },
    [
      setPdpHasUserSelectedOptions,
      setPdpLastSelectionFrom,
      setPdpLastSelectedOption,
      setUserSelectedOptionsExtended,
    ]
  );

  const history = useHistory();
  const updateUrlQueryParams = usePDPUpdateUrlQueryParams();

  const onOptionValueSelection = useCallback(
    (optionId, valueId, invokedFrom = null, isSelectedByUser = true) => {
      const selectedProductOption = allProductOptionsForSelectedTab?.find(
        ({ id: oId }) => oId === optionId
      );

      const selectedValue = allProductOptionsForSelectedTab
        ?.find(({ id: oId }) => oId === optionId)
        ?.values.find(({ id: vId }) => vId === valueId);

      if (!selectedValue) {
        return;
      }

      if (shouldUpdateQueryParam) {
        updateUrlQueryParams(selectedProductOption, selectedValue);
      } else if (history?.location?.search) {
        // remove if any params in url.
        const modifiedSearchParams = removeDeepLinkURLParams(
          history?.location?.search
        );
        history.replace({
          search: modifiedSearchParams ? `?${modifiedSearchParams}` : '',
        });
      }

      setPdpSelectedOption(
        optionId,
        selectedValue,
        invokedFrom,
        isSelectedByUser
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allProductOptionsForSelectedTab, setPdpSelectedOption]
  );

  const setPdpLastHoveredOptionsExtended = useSetAtom(
    pdpLastHoveredOptionsExtendedAtom
  );

  const setPdpLastHoveredOption = useCallback(
    (optionId, value) => {
      if (value?.id && value?.sku && value?.value) {
        setPdpLastHoveredOptionsExtended({
          ...selectedOptionsExtended,
          [optionId]: {
            value: {
              id: value.id,
              sku: value.sku,
              value: value.value,
            },
          },
        });
      }
    },
    [selectedOptionsExtended, setPdpLastHoveredOptionsExtended]
  );

  const onOptionValueHover = useCallback(
    (optionId, valueId) => {
      if (optionId && valueId) {
        const hoveredValue = allProductOptionsForSelectedTab
          .find(({ id: pId }) => pId === optionId)
          .values.find(({ id: vId }) => vId === valueId);

        setPdpLastHoveredOption(optionId, hoveredValue);
      }
    },
    [allProductOptionsForSelectedTab, setPdpLastHoveredOption]
  );

  // Derive the variant SKU for the selected options
  const selectedOptionsVariantSku = useMemo(
    () =>
      getVariantSkuForSelectedOptionsExtended(
        selectedOptionsExtended,
        pdpOptionsOrder
      ),
    [pdpOptionsOrder, selectedOptionsExtended]
  );

  // Get other data required for the context value
  const allProtectionPlanTypes = useAllProtectionPlanTypes();

  // Derive other data required for the context value
  const {
    hasSingleOptionType,
    hasSingleOptionValue,
  } = usePDPOptionsHasSingleOption({
    id,
    productOptions,
    slug,
  });

  const {
    orientationOptionId,
    orientationOptionValueId,
  } = usePDPOptionsOrientationIds({ productOptions });

  const pdpOptionsOrderAtom = useMemo(() => atom(pdpOptionsOrder), [
    pdpOptionsOrder,
  ]);

  const upholsteryOptionId = useMemo(
    () => productOptions?.map(({ id: sId }) => sId).find(isUpholsteryOption),
    [productOptions]
  );

  return {
    atoms: {
      pdpHasUserSelectedOptionsAtom,
      pdpLastHoveredOptionsExtendedAtom,
      pdpLastSelectedOptionIdAtom,
      pdpLastSelectionFromAtom,
      pdpOptionsOrderAtom,
      pdpSelectedCustomizerTabAtom,
      pdpSelectedOptionsExtendedAtom: userSelectedOptionsExtendedAtom,
    },
    productOptions: {
      loading:
        productOptionsLoading ||
        !productOptions ||
        (productOptions?.length > 0 && !selectedOptionsVariantSku) ||
        stock.loading ||
        (!id && !slug),
      imgsloading: productOptionsLoading || !productOptions || (!id && !slug),
      error: productOptionsError,
      data: {
        allProtectionPlanTypes,
        hasOrientation: {
          defaultValueId: orientationOptionValueId,
          optionId: orientationOptionId,
        },
        hasSingleOptionType,
        hasSingleOptionValue,
        options: productOptions,
        pdpHasUserSelectedOptions,
        productId: id,
        selectedOptions,
        selectedOptionsExtended,
        selectedOptionsFromURLOverride,
        selectedOptionsVariantSku,
        stock,
        upholsteryOptionId,
      },
      actions: {
        onOptionValueHover,
        onOptionValueSelection,
      },
    },
  };
};

export default usePDPCreateProductOptions;
