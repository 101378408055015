import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';
import Passthrough from 'components/consumer/Passthrough';

const DesktopOnlyLegacy = loadable(() => import('./DesktopOnlyLegacy'));

// This component conditionally only renders children on desktop in the legacy
// consumer app, but in the new app will render at all breakpoints as required
// by React 18.
const DesktopOnly = isNewConsumerApp ? Passthrough : DesktopOnlyLegacy;

export default DesktopOnly;
