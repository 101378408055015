import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToggleSwitch = ({
  className = '',
  isChecked = false,
  children,
  name,
  onClick = () => null,
  size = 'md',
  toggleVisible = true,
}) => (
  <button
    type="button"
    className={`flex items-center hover:cursor-pointer ${className}`}
    onClick={onClick}
    disabled={!toggleVisible}
  >
    <input
      className="absolute h-px w-px overflow-hidden whitespace-nowrap outline-0"
      type="checkbox"
      name={name}
      checked={isChecked}
      onChange={() => {}}
    />

    {toggleVisible && (
      <span
        className={classNames(
          'bg-gray-light3 rounded-xl relative h-6 w-9 transition-[background-color] duration-[130ms] ease-[cubic-bezier(0.4,0,0.6,1)]',
          { '!bg-brand': isChecked }
        )}
      >
        <span
          className={classNames(
            'rounded-3xl h-5 w-5 absolute top-0.5 left-0.5 bg-white transition-[left] duration-[130ms] ease-[cubic-bezier(0.4,0,0.6,1)]',
            { '!left-3.5': isChecked }
          )}
        />
      </span>
    )}

    {children && (
      <span
        className={classNames(
          'text-base font-bold color-gray ml-3 select-none text-left break-words',
          {
            '!text-sm !ml-2': size === 'sm',
            '!ml-0': !toggleVisible,
          }
        )}
      >
        {children}
      </span>
    )}
  </button>
);

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md']),
  toggleVisible: PropTypes.bool,
};

export default ToggleSwitch;
