import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { product_item_availability } from 'commons/constants';
import getBreadcrumbsForPage from 'commons/getBreadcrumbsForPage';
import useProductOrderETA from '../useProductOrderETA';
import { findAvailabilityAndVisibility } from '../utils';

/**
 *  Used in the PDP to determine if product is out of stock
 *  or discontinued based on stock response (both EFG internal and FFG).
 *  This controls the notification displayed in the PDP.
 *
 *  Returns object of shape
 *  {
 *    loading,
 *    error
 *    data : {
 *     isOutOfStock,
 *     showNotification,
 *     isEFGInternal,
 *     isDiscontinued
 *    }
 *  }
 */

const OUT_OF_STOCK_ALERT_DEFAULT = {
  show: false,
  productId: null,
};

const usePDPGetStockAvailability = ({ basicDetails, productOptions }) => {
  const {
    loading: basicDetailsLoading,
    error: basicDetailsError,
    data: basicDetailsData,
  } = basicDetails;

  const productId = basicDetailsData?.id;

  const {
    loading: optionsLoading,
    data: optionsData,
    error: optionsError,
  } = productOptions;

  const isEFGProduct = !!basicDetailsData?.efg_flag;
  const isExternalProduct = !!basicDetailsData?.is_external;
  const isEFGInternal = isEFGProduct && !isExternalProduct;
  const isEFGExternal = isEFGProduct && isExternalProduct;
  const isMarkedDiscontinued = !!basicDetailsData?.discontinued;

  const isMarkedOOS = useMemo(() => {
    const { availability } = findAvailabilityAndVisibility(
      basicDetailsData?.attributes || []
    );

    if (
      `${availability}` === `${product_item_availability.out_of_stock}` ||
      `${availability}` === `${product_item_availability.disabled}`
    ) {
      return true;
    }

    return false;
  }, [basicDetailsData?.attributes]);

  const { stock = null } = optionsData;
  const isStockLoading = !!stock?.loading;

  const isMarkedOOSOrDiscontinued = !!(
    productId &&
    (isMarkedOOS || isMarkedDiscontinued)
  );

  const isProductOrStockLoading =
    !basicDetailsData ||
    !stock ||
    isStockLoading ||
    typeof stock?.inStockOptionIds === 'undefined' ||
    productId !== stock?.idForStock;

  const inStockOptionIds = stock?.inStockOptionIds;
  const selectedOptionsStockQty = stock?.selectedOptionsStockQty;

  const isOutOfStock =
    !isProductOrStockLoading &&
    ((isEFGExternal && isMarkedOOS) ||
      (isEFGInternal &&
        !isMarkedOOSOrDiscontinued &&
        !inStockOptionIds?.length &&
        !selectedOptionsStockQty) ||
      (!inStockOptionIds?.length && !selectedOptionsStockQty && isMarkedOOS));

  const isDiscontinued =
    !isProductOrStockLoading &&
    ((isEFGExternal && isMarkedDiscontinued) ||
      (!inStockOptionIds?.length &&
        !selectedOptionsStockQty &&
        isMarkedDiscontinued));

  const shouldGetProductOrderETA =
    !isProductOrStockLoading &&
    (isMarkedOOS || (isEFGInternal && !isMarkedOOSOrDiscontinued));

  const params = useParams();
  const categoryName = params?.category;

  const discontinuedCategoryMsg = useMemo(() => {
    if (!isDiscontinued || !productId) {
      return null;
    }

    const allBreadCrumbs = getBreadcrumbsForPage('pdp');

    const productBreadCrumbs =
      allBreadCrumbs?.[categoryName] &&
      allBreadCrumbs?.[allBreadCrumbs[categoryName]];

    const finalCategory = productBreadCrumbs?.length
      ? productBreadCrumbs.pop()
      : null;

    if (finalCategory) {
      const updatedDiscontinuedMsg = {
        category: finalCategory.label,
        link: finalCategory.link,
        forId: productId,
      };

      return updatedDiscontinuedMsg;
    }

    return null;
  }, [isDiscontinued, productId, categoryName]);

  const { loading: etaLoading, eta } = useProductOrderETA(
    shouldGetProductOrderETA ? basicDetailsData.id : null
  );

  const outOfStockAlert = useMemo(() => {
    if (isOutOfStock) {
      return {
        eta: eta && !etaLoading ? eta : undefined,
        productId,
        show: true,
      };
    }

    return OUT_OF_STOCK_ALERT_DEFAULT;
  }, [productId, eta, etaLoading, isOutOfStock]);

  return {
    loading: basicDetailsLoading || optionsLoading,
    error: basicDetailsError || optionsError,
    data: {
      discontinuedCategoryMsg,
      isDiscontinued,
      isEFGInternal,
      isMarkedOOSOrDiscontinued,
      isOutOfStock,
      isStockLoading,
      showNotification: outOfStockAlert,
    },
  };
};

export default usePDPGetStockAvailability;
