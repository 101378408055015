import React, { memo } from 'react';
import DOMPurify from 'isomorphic-dompurify';

const SanitizedHtml = ({ as: AsHtmlElement = 'div', html, ...props }) => {
  const sanitizedHtmlObject = {
    __html: DOMPurify.sanitize(html),
  };

  return (
    <AsHtmlElement dangerouslySetInnerHTML={sanitizedHtmlObject} {...props} />
  );
};

export default memo(SanitizedHtml);
