import useSuspenseQuery from 'commons/useSuspenseQuery';

import useUserLocation from 'global-state/userLocation/useUserLocation';
import { GET_CLOSEST_STORE_ZIPCODE } from 'mocks/queries';
import useIsBrowser from 'hooks/useIsBrowser';

import storesData from './stores.json';

const useClosestStoreZipcode = () => {
  const { zip: userZipcode } = useUserLocation();
  const isBrowser = useIsBrowser();

  const skip = !isBrowser || !userZipcode;

  const { loading, data: storeZipData } = useSuspenseQuery(
    GET_CLOSEST_STORE_ZIPCODE,
    {
      variables: {
        zipcode: userZipcode?.toString(),
      },
      skip,
    }
  );

  const storeZip = storeZipData?.getClosestStoreFromZipcode?.toString();

  return {
    storeLocation: storeZip ? storesData[storeZip] : storesData.virtual,
    isStoreZipLoading: !skip && loading,
  };
};

export default useClosestStoreZipcode;
