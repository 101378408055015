import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import useAllProductClearanceStock from 'data-hooks/useAllProductClearanceStock';
import { GET_CUSTOM_LIST_PRODUCT_DATA } from 'mocks/queries';

const FLASH_SALE_SLUG = 'flash-sale';

const useFlashSaleList = (skipQuery = false, skipHook = {}) => {
  const { skip = false, defaultValue = {} } = skipHook;

  const { loading, data } = useSuspenseQuery(GET_CUSTOM_LIST_PRODUCT_DATA, {
    variables: { slug: FLASH_SALE_SLUG },
    skip: skipQuery,
  });

  const { data: clearanceData } = useAllProductClearanceStock(skip);

  const flashSaleData = useMemo(() => {
    if (loading || !data?.getCustomListProductData || isEmpty(clearanceData)) {
      return {
        loading: true,
        products: [],
        variations: {},
        meta: null,
      }
    }
    
    const {
      products: productsList = '',
      metadata = null,
      products_list = [],
    } = data.getCustomListProductData;

    const finalProductIds = productsList
      .split(',')
      .map(id => parseInt(id, 10))
      .filter(v => !!v);

    if (!finalProductIds?.length) {
      // https://stitchindustries.atlassian.net/browse/CON-3938
      // No products in flash sale list
      return {
        loading: false,
        products: [],
        variations: {},
        meta: null,
      }
    }

    const variationsObj = {};
  
    const finalOptionValuesList = products_list?.length
      ? products_list.map(prod => {
          const optObj = {
            fullSku: '',
          };
          prod.selectedOptions.forEach(optionValue => {
            optObj[optionValue.optionId] = optionValue.optionValue;
            optObj.fullSku += optionValue.optionValueSku;
          });
          return optObj;
        })
      : [];

    const finalClearanceIds = [];

    // Only add products with valid clearance stock
    finalProductIds.forEach((prodId, index) => {
      const prodOptions = finalOptionValuesList[index];
      const allProdOptions = products_list?.[index]?.selectedOptions || [];
      const clearanceStockPerSKU = clearanceData[prodId]?.[prodOptions.fullSku];
      const isClearance = !isEmpty(clearanceStockPerSKU);
      if (!isEmpty(prodOptions) && isClearance) {
        finalClearanceIds.push(prodId);
        Object.keys(prodOptions).forEach(optionId => {
          if (['17', '18', '148', '165', '163'].includes(optionId)) {
            if (!variationsObj[prodId]) {
              variationsObj[prodId] = {
                [optionId]: [],
              };
            }
            const optionValue = allProdOptions.find(
              prodOpt =>
                parseInt(prodOpt.optionId, 10) === parseInt(optionId, 10)
            );
            if (optionValue) {
              variationsObj[prodId][optionId].push(optionValue.optionValueId);
            }
          }
        });
      }
    });

    return {
      loading,
      products: finalClearanceIds,
      variations: variationsObj,
      meta: metadata,
    }
  }, [clearanceData, data?.getCustomListProductData, loading]);

  if (skip) {
    return defaultValue;
  }

  return flashSaleData;
};

export default useFlashSaleList;
