import { useEffect, useState } from 'react';

// This hook allows us to implement conditional behavior that only occurs in the
// browser, by checking if the component has mounted. During the initial render
// it returns false in both browser and server environments, which prevents SSR
// hydration errors.

// It should not normally be used for elements that affect initial layout,
// since this will cause a flash of incorrect content in the browser.

// It is suitable for:
// - Conditionally rendering elements that are not displayed initially, e.g.
//   modals, popovers
// - Conditionally rendering elements that do not affect overall layout, e.g.
//   close buttons that are absolutely positioned
// - Callback functions that are only called in the browser, e.g. analytics
//   calls, onClick handlers

const useIsBrowser = () => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser;
};

export default useIsBrowser;
