import isClient from 'commons/isClient';

const fakeStorageObject = {};

const fakeStorage = {
  setItem: (key, data) => { fakeStorageObject[key] = data.toString() },
  getItem: key => fakeStorageObject[key] || null,
  removeItem: key => delete fakeStorage[key],
};

const browserStorage = (preferSession = false) => {
  let storage = fakeStorage;
  if (isClient()) {
    try {
      if (preferSession) {
        storage = window.sessionStorage || window.localStorage;
      } else {
        storage = window.localStorage || window.sessionStorage;
      }
    } catch (e) {
      storage = fakeStorage;
    }
  }
  return storage;
};

export default browserStorage;
