import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import VisuallyHidden from 'components/consumer/VisuallyHidden';

const LabeledFieldWrapper = ({
  action,
  actionAlignment = 'right',
  actionHasPadding = true,
  actionStretchHeight = false,
  backgroundColor = 'white',
  children,
  className = '',
  disabled,
  errorMessage,
  hasPlaceholder = false,
  hasValue = false,
  inputId,
  label,
  labelSize,
  noMargin = false,
  placeholder,
  postfixText,
  prefixText,
  prefixWidth,
  shrinkLabelOnFocus = true,
  size = 'md',
  variant = 'default',
  fullHeight = false,
}) => {
  const accessibleLabel = label || placeholder;
  const hasErrorMessage = !!errorMessage;
  const hasPrefixText = !!prefixText;

  const isLabelTextScaledDown = hasValue || hasPlaceholder || hasPrefixText;
  const isLivestreamSearch = variant === 'livestream-search';
  const isRangeFilter = variant === 'range-filter';

  return (
    <div
      className={classNames(
        `group/LabeledFieldWrapper mx-0 relative ${className}`,
        {
          'text-gray-light2 cursor-not-allowed [&_input]:cursor-not-allowed': !!disabled,
          'my-3': !noMargin,
          'my-0': noMargin,
          '[&_input[type=number]]:![appearance:textfield] [&_input[type=number]::-webkit-inner-spin-button]:!appearance-none [&_input[type=number]::-webkit-inner-spin-button]:!m-0': isRangeFilter,
        }
      )}
      data-xc="LabeledFieldWrapper"
      data-has-error={errorMessage ? 'true' : undefined}
    >
      {!!accessibleLabel && (
        <VisuallyHidden>
          <label htmlFor={inputId}>{accessibleLabel}</label>
        </VisuallyHidden>
      )}

      <div
        className={classNames(
          'items-stretch bg-white rounded-sm border border-solid flex justify-between w-full',
          {
            '!bg-gray-light9 !border-gray-light9': backgroundColor === 'gray',
            'min-h-[3rem]': size === 'xs',
            'min-h-[38px]': size === 'sm',
            'min-h-[56px]': size === 'md',
            'border-gray-light3': !hasErrorMessage,
            'group-focus-within/LabeledFieldWrapper:border-gray-dark group-focus-within/LabeledFieldWrapper:[outline-style:auto] group-focus-within/LabeledFieldWrapper:outline-1 group-focus-within/LabeledFieldWrapper:outline-gray-dark': !hasErrorMessage,
            'outline outline-1 outline-red border-red': hasErrorMessage,
            'flex-row-reverse': actionAlignment === 'left',
            '!h-full': fullHeight,
          }
        )}
      >
        <div
          className={classNames('flex-1', {
            'px-[13px] pt-[21px] pb-2':
              (size === 'sm' || size === 'md') && !hasErrorMessage,
            'px-2.5 pt-[21px] pb-2': size === 'xs' && !hasErrorMessage,
            'pt-[15px] pb-px': size === 'sm' && !hasErrorMessage,
            'pt-[23px] px-3 pb-[7px]': hasErrorMessage && !hasPrefixText,
            'pt-[21px] px-[13px] pb-2': hasErrorMessage && hasPrefixText,
            'pt-3.5 pb-0': size === 'sm' && hasErrorMessage,
          })}
        >
          {hasPrefixText && (
            <span
              className={classNames(
                'absolute pointer-events-none right-3.5 [transition:all_.2s_ease-out] text-gray-light2',
                {
                  'left-3.5': actionAlignment === 'right',
                  'left-[50px]': actionAlignment === 'left',
                  'text-base': !hasPrefixText,
                  'text-lg': hasPrefixText,
                  'top-[7px]': size === 'sm' && !hasPrefixText,
                  'top-[16px]': size === 'sm' && hasPrefixText,
                  'top-[17px]': size === 'md' && !hasPrefixText,
                  'top-[25px]': size === 'md' && hasPrefixText,
                }
              )}
            >
              {prefixText}
            </span>
          )}

          <span
            className={classNames(
              'text-gray absolute pointer-events-none right-3.5 [transition:all_.2s_ease-out]',
              {
                // Label text is full size
                'top-[7px]': size === 'sm' && !isLabelTextScaledDown,
                'top-[17px]':
                  size === 'md' && !isLabelTextScaledDown && labelSize !== 'sm',
                'top-[12px] bottom-[12px] flex items-center':
                  size === 'md' && !isLabelTextScaledDown && labelSize === 'sm',
                'text-base': labelSize === 'md' && !isLabelTextScaledDown,
                'text-sm leading-4':
                  labelSize === 'sm' && !isLabelTextScaledDown,
                // Label text is scaled down
                'text-xs truncate': isLabelTextScaledDown,
                'top-0': size === 'sm' && isLabelTextScaledDown,
                'top-[9px]': size === 'md' && isLabelTextScaledDown,
                'group-focus-within/LabeledFieldWrapper:text-xs group-focus-within/LabeledFieldWrapper:truncate group-focus-within/LabeledFieldWrapper:inline': shrinkLabelOnFocus,
                'group-focus-within/LabeledFieldWrapper:top-0':
                  size === 'sm' && shrinkLabelOnFocus,
                'group-focus-within/LabeledFieldWrapper:top-[9px]':
                  size === 'md' && shrinkLabelOnFocus,
                'left-3.5': actionAlignment === 'right',
                'left-[50px]': actionAlignment === 'left',
              }
            )}
            aria-hidden
          >
            {label}
          </span>

          <span
            className="[&_input]:pt-[7px]"
            style={prefixWidth ? { marginLeft: prefixWidth } : {}}
          >
            {children}
          </span>

          {!!postfixText && (
            <span
              className={classNames(
                'text-base text-gray absolute right-3.5 pointer-events-none [transition:all_.2s_ease-out]',
                {
                  'top-[25px]': size !== 'sm',
                  'top-[7px]': size === 'sm',
                }
              )}
            >
              {postfixText}
            </span>
          )}
        </div>

        {!!action && (
          <div
            className={classNames('flex', {
              'pt-[17px]': isLivestreamSearch,
              'items-center': !actionStretchHeight,
              'items-stretch': actionStretchHeight,
              'pr-3': actionHasPadding && actionAlignment === 'right',
              'pl-3 pr-0': actionHasPadding && actionAlignment === 'left',
            })}
          >
            {action}
          </div>
        )}
      </div>

      <div role="alert" id={`${inputId}-error`}>
        {errorMessage && (
          <span className="text-xs font-bold text-red">
            <VisuallyHidden>{`Error: `}</VisuallyHidden>
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

LabeledFieldWrapper.propTypes = {
  action: PropTypes.element,
  actionAlignment: PropTypes.oneOf(['left', 'right']),
  actionHasPadding: PropTypes.bool,
  actionStretchHeight: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(['white', 'gray']),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasPlaceholder: PropTypes.bool,
  hasValue: PropTypes.bool,
  inputId: PropTypes.string,
  label: PropTypes.string,
  labelSize: PropTypes.oneOf(['sm', 'md']),
  noMargin: PropTypes.bool,
  placeholder: PropTypes.string,
  postfixText: PropTypes.string,
  prefixText: PropTypes.string,
  prefixWidth: PropTypes.number,
  shrinkLabelOnFocus: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  variant: PropTypes.oneOf(['default', 'livestream-search', 'range-filter']),
  fullHeight: PropTypes.bool,
};

export default LabeledFieldWrapper;
