import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useScreen from 'hooks/useScreen';
import useProductCardContext from 'data-hooks/useProductCardContext';
import BarbieLogo from 'assets/images/made-for-barbie-logo.png';
import BipocLogo from 'assets/images/bipoc-badge.png';
import IconGreenguardGold from 'components/icons/PDPSellingAttributes/IconGreenguardGold';
import isT1Image from 'commons/isT1Image';
import { IMAGE_FLIP_SKUS } from 'commons/constants';
import ProductCardConfigImage from './ProductCardConfigImage';
import ProductCardStudioImage from './ProductCardStudioImage';
import ProductCardPhotoSlider from './ProductCardPhotoSlider';

const ProductCardPhotoSquare = ({ enableMobileCarousel }) => {
  const {
    image,
    secondaryImage,
    secondaryImageCropData,
    variantsToShow,
    campaign,
    sku,
  } = useProductCardContext();

  const { isMobile } = useScreen();
  const isDesktop = !isMobile;

  const isFlipped =
    sku?.split('-').some(optionSku => IMAGE_FLIP_SKUS.includes(optionSku)) ??
    false;

  // If this product has no swatches shown (i.e. it's a single variant product),
  // the primary image is missing and we have a secondary image, then we want to
  // show the secondary image as the primary image, and not show a hover image.
  const showSecondaryImageAsPrimary =
    !variantsToShow?.length && !image && !!secondaryImage;

  const primaryImageElement = (
    <ProductCardConfigImage
      image={image}
      campaign={campaign}
      isFlipped={isFlipped}
    />
  );

  const isSecondaryConfigImage =
    isT1Image(secondaryImage) && !secondaryImageCropData;

  const secondaryImageElement = useMemo(() => {
    if (!secondaryImage) {
      return null;
    }

    if (isSecondaryConfigImage) {
      return (
        <ProductCardConfigImage image={secondaryImage} isFlipped={isFlipped} />
      );
    }

    return (
      <ProductCardStudioImage
        cropData={secondaryImageCropData}
        image={secondaryImage}
        isMobile={isMobile}
      />
    );
  }, [
    isMobile,
    isSecondaryConfigImage,
    secondaryImage,
    secondaryImageCropData,
    isFlipped,
  ]);

  const firstImageToShow = showSecondaryImageAsPrimary
    ? secondaryImageElement
    : primaryImageElement;

  const secondImageToShow = showSecondaryImageAsPrimary
    ? null
    : secondaryImageElement;

  const showCarouselOnMobile = enableMobileCarousel && !!secondImageToShow;

  return (
    <div className="aspect-square relative bg-gray-light8 flex flex-col [transition:background-color_0.3s_ease-in-out] items-center justify-center">
      {(isDesktop || !showCarouselOnMobile) && firstImageToShow}
      <div className="w-full flex flex-row justify-end absolute bottom-2">
        {campaign === 'barbie' && (
          <img
            src={BarbieLogo}
            alt="barbie-logo"
            className="w-12 h-12 absolute bottom-2 right-4"
          />
        )}
        {campaign === 'bipoc' && (
          <img
            src={BipocLogo}
            alt="bipoc-owned-logo"
            className="w-12 h-12 absolute bottom-2 right-4"
          />
        )}
        {campaign === 'greenguard' && (
          <IconGreenguardGold className="w-12 h-12 absolute bottom-2 right-4 z-10" />
        )}
      </div>

      {isDesktop && !!secondImageToShow && (
        <div className="absolute w-full h-full top-0 left-0 transition-opacity duration-700 ease-out opacity-0 group-hover/product-card:opacity-100 bg-gray-light8 flex flex-col items-center justify-center">
          {secondImageToShow}
        </div>
      )}

      {isMobile && showCarouselOnMobile && (
        <ProductCardPhotoSlider
          image1={firstImageToShow}
          image2={secondImageToShow}
        />
      )}
    </div>
  );
};

ProductCardPhotoSquare.propTypes = {
  enableMobileCarousel: PropTypes.bool,
};

export default ProductCardPhotoSquare;
