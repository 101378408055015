import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProductCardPhotoSliderDotsItem = ({ isActive }) => (
  <div
    className={classNames(
      'rounded-full h-1.5 w-1.5 m-1 inline-block transition-all duration-700 ease-out [box-shadow:0_0_0_1px_rgba(255,255,255,0.3)]',
      {
        'opacity-80 bg-gray-light4': !isActive,
        'opacity-100 bg-gray-light2': isActive,
      }
    )}
  />
);

ProductCardPhotoSliderDotsItem.propTypes = {
  isActive: PropTypes.bool,
};

const ProductCardPhotoSliderDots = ({ activeItem = 0, itemsCount = 2 }) => (
  <div className="absolute bottom-2 left-0 right-0 flex items-center justify-center pointer-events-none">
    {Array(itemsCount)
      .fill(itemsCount)
      .map((_, itemNumber) => itemNumber)
      .map(itemNumber => (
        <ProductCardPhotoSliderDotsItem
          key={itemNumber}
          isActive={activeItem === itemNumber}
        />
      ))}
  </div>
);

ProductCardPhotoSliderDots.propTypes = {
  activeItem: PropTypes.number,
  itemsCount: PropTypes.number,
};

export default ProductCardPhotoSliderDots;
