import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FixedWidthContainer = React.forwardRef(
  (
    {
      children,
      className = '',
      'data-xc': dataXc,
      noMobilePadding = false,
      noTabletPadding = false,
      noPadding = false,
      slim = false,
      wide = false,
    },
    ref
  ) => (
    <div
      className={classNames(`mx-auto my-0 py-0 ${className}`, {
        'px-5': !noMobilePadding && !noPadding,
        'px-0': noMobilePadding,
        'md:px-[3.333vw] min-[1500px]:px-[50px]': !noPadding,
        'md:px-0': noPadding,
        'max-lg:md:px-0': noTabletPadding,
        'max-w-[1500px]': !slim && !wide,
        'max-w-[840px]': slim,
        'max-w-[1800px]': wide,
      })}
      ref={ref}
      data-xc={dataXc}
    >
      {children}
    </div>
  )
);

FixedWidthContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  'data-xc': PropTypes.string,
  noMobilePadding: PropTypes.bool,
  noTabletPadding: PropTypes.bool,
  noPadding: PropTypes.bool,
  slim: PropTypes.bool,
  wide: PropTypes.bool,
};

export default FixedWidthContainer;
