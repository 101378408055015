import { DEFAULT_IMAGE_HOST } from 'commons/constants';

const addDefaultImgixDomain = imageUrl =>
  imageUrl?.startsWith('/') ? `${DEFAULT_IMAGE_HOST}${imageUrl}` : imageUrl;

// Replace spaces in attribute values with dashes
const getAttributesWithoutSpaces = (attributes = []) =>
  attributes.map(attribute => ({
    ...attribute,
    values: attribute.values.map(value => value.replace(/\s/g, '-')),
  }));

// To avoid sending unnecessary data via the API, we use this function to add
// any data which can be derived from the server data.
const addDerivedProductData = ({
  product: { variants: rawVariants, path, attributes, ...product },
  upholsteryOptions,
}) => {
  // Rename slug property to id. We can't use id directly in the server response
  // because of an issue in our current Apollo resolvers.
  const variants = rawVariants.map(
    ({ slug: id, image, path: variantPath = '', ...variant }) => {
      const matchingUpholstery = upholsteryOptions?.find(
        upholstery => upholstery.id === id
      );

      return {
        ...variant,

        color: matchingUpholstery?.color,
        id,
        image: addDefaultImgixDomain(image),
        path: `${path}${variantPath}`,
        thumbnail: addDefaultImgixDomain(matchingUpholstery?.thumbnail ?? ''),
      };
    }
  );

  return {
    ...product,
    attributes: getAttributesWithoutSpaces(attributes),
    path,
    // Create a unique productCardKey that is based on the product slug and the
    // variant sku of every specified variant. This allows us to render
    // multiple product card for the same slug, provided that they are
    // rendering different variants. By deriving the key here, it will only be
    // calculated once per product for each query, rather than being
    // recalculated every time each product card is rendered.
    productCardKey: `${product.slug}-${variants
      .map(({ sku }) => {
        const optionSku = sku
          ?.split('-')
          .slice(1)
          .join('-');
        return optionSku;
      })
      .join('-')}`,
    secondaryImage: addDefaultImgixDomain(product.secondaryImage),
    variants,
    // TODO: Replace hardcoded data below with real data once available from API
    isClearance: false,
  };
};

export default addDerivedProductData;
