import { useRouteMatch, matchPath } from 'react-router-dom';
import isClient from 'commons/isClient';
import avo from 'analytics/avo';

export const getUserCurrentPageType = routeMatch => {
  if (routeMatch.path === '/') {
    return avo.Location.HOME_PAGE;
  }
  if (
    [
      '/:slug',
      '/collections/:slug',
      '/collections',
      '/products',
      '/instock',
      '/clearance',
      '/modular-sofas',
    ].includes(routeMatch.path)
  ) {
    return avo.Location.CATEGORY_PAGE;
  }
  if (
    [
      '/:category/:slug',
      '/modular/:slug',
      '/:category/:slug/:compare?',
    ].includes(routeMatch.path)
  ) {
    return avo.Location.PDP;
  }

  if (/^\/checkout/.test(routeMatch.path)) {
    return avo.Location.CHECKOUT;
  }

  if (/^\/cart/.test(routeMatch.path)) {
    return avo.Location.CART;
  }

  if (/^\/pet-friendly/.test(routeMatch.path)) {
    return avo.Location.PET_FRIENDLY_PAGE;
  }

  if (/^\/customer\//.test(routeMatch.path)) {
    return avo.Location.CUSTOMER_DASHBOARD;
  }

  if (/^\/material-swatches/.test(routeMatch.path)) {
    return avo.Location.SHOP_BY_MATERIAL;
  }

  if (/^\/safeguard-fabrics/.test(routeMatch.path)) {
    return avo.Location.SAFEGUARD_FABRICS_PAGE;
  }
  if (/^\/lookbooks/.test(routeMatch.path)) {
    return avo.Location.LOOKBOOKS;
  }
  if (/^\/live/.test(routeMatch.path)) {
    return avo.Location.JOYBIRD_LIVE;
  }

  if (/^\/blog/.test(routeMatch.path)) {
    return avo.Location.BLOG;
  }
  if (/^\/videos/.test(routeMatch.path)) {
    return avo.Location.VIDEO_GALLERY;
  }

  if (/^\/free-design-service/.test(routeMatch.path)) {
    return avo.Location.DESIGN_SERVICES;
  }
  return avo.Location.HOME_PAGE; // for other routes we are passing HOME for now;
};

export const isPDPPath = pathUrl => {
  const defaultMatch = {
    path: '/',
    exact: true,
    strict: false,
  };
  const match = matchPath(pathUrl, {
    ...defaultMatch,
    path: ['/:category/:slug', '/modular/:slug', '/:category/:slug/:compare?'],
  });

  return !!match;
};

const useUserCurrentPageType = () => {
  const routeMatch = useRouteMatch();
  const pageType = getUserCurrentPageType(routeMatch);

  return !isClient() ? avo.Location.HOME_PAGE : pageType;
};
export default useUserCurrentPageType;
