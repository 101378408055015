import { useMemo } from 'react';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_PRODUCTS_ORDER_ETA } from 'mocks/queries';
import getFormattedDate from './getFormattedDate';

const useProductOrderETA = productId => {
  const { loading, data } = useSuspenseQuery(GET_PRODUCTS_ORDER_ETA, {
    variables: {
      productIds: [productId],
    },
    skip: !productId,
    ssr: true,
  });

  const eta = useMemo(() => {
    const dateString = data?.getOrderETAForProductIds?.[productId];

    if (!productId || loading || !dateString) {
      return '';
    }

    const formattedDate = getFormattedDate(dateString);

    return formattedDate;
  }, [loading, data, productId]);

  return {
    eta,
    loading,
  };
};

export default useProductOrderETA;
