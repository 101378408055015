import React, { useState } from 'react';
import { colorBrand } from 'styles/layout/colors';
import IconMapMarker from 'components/icons/IconMapMarker';
import avo from 'analytics/avo';
import { storeUrlSlugMapping } from 'commons/ShowroomStaticData';
import useProductCardContext from 'data-hooks/useProductCardContext';
import ProductCardShowroomModal from './ProductCardShowroomModal';

// Create array of stores in storeUrlSlugMapping which include path
const storesWithPaths = Object.entries(
  storeUrlSlugMapping
).map(([storePath, store]) => ({ ...store, path: storePath }));

const getStoreData = ({ slug }) => {
  if (slug) {
    return storesWithPaths.find(store => store?.slug === slug);
  }

  return null;
};

const ProductCardOnDisplayInShowroom = () => {
  const productCardContextData = useProductCardContext();
  const [modalOpen, setModalOpen] = useState(false);
  const slug = productCardContextData?.onDisplayInShowroomSlug;

  const storeData = getStoreData({ slug });
  const path = storeData?.path;
  const showroomData = storeData?.showroom;

  // If showroom data is not available, do not render component
  if (!showroomData) {
    return null;
  }

  const modalOnClose = () => {
    setModalOpen(false);
  };

  const toggleModal = () => {
    if (!modalOpen) {
      avo.ctaClicked({
        text: `On display in ${showroomData.name}`,
        targetUrl: path,
        cta: 'Retail Store',
        component: 'Side Panel',
        location: 'PDP',
      });
    }
    setModalOpen(!modalOpen);
  };

  return (
    <div className="text-xs font-bold text-brand cursor-pointer bg-transparent border-0 pb-[5px] text-left max-[767px]:pt-0 max-[767px]:pr-0 max-[767px]:pb-[5px] max-[767px]:pl-[5px]">
      <button
        className="flex md:flex-row md:items-center flex-col items-start text-xs font-bold text-brand cursor-pointer bg-transparent border-0 pb-[5px] text-left max-[767px]:pt-0 max-[767px]:pr-0 max-[767px]:pb-[5px] max-[767px]:pl-[5px]"
        onClick={toggleModal}
        type="button"
        aria-label="Open Showroom"
      >
        <span className="text-xs font-bold text-gray-light2">
          On display in&nbsp;
        </span>

        <span className="text-xs font-bold text-brand hover:cursor-pointer hover:no-underline flex space-x-2 items-center">
          <span>
            <IconMapMarker height={12} width={12} color={colorBrand} />
          </span>

          <span> {showroomData.name}</span>
        </span>
      </button>

      <ProductCardShowroomModal
        data={showroomData}
        onClose={modalOnClose}
        isOpen={modalOpen}
      />
    </div>
  );
};

export default ProductCardOnDisplayInShowroom;
