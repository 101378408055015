const getExtendedSelectedOptions = ({ productOptions, selectedOptions }) => {
  const extendedSelectedOptions = Object.entries(selectedOptions).reduce(
    (acc, [optionId, valueId]) => {
      const option = productOptions.find(({ id }) => +id === +optionId);

      if (option) {
        const value = option.values.find(({ id }) => +id === +valueId);

        if (value) {
          return {
            ...acc,
            [optionId]: {
              value,
            },
          };
        }
      }
      return acc;
    },
    {}
  );

  return extendedSelectedOptions;
};

export default getExtendedSelectedOptions;
