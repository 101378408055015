import { useCallback } from 'react';
import { isEqual } from 'lodash';

import getExtendedSelectedOptionsFromFullFormat from 'components/consumer/PDP/state/helpers/getExtendedSelectedOptionsFromFullFormat';
import useSetPdpSelectedOptionsExtended from '../pdpSelectedOptionsExtended/usePdpSetSelectedOptionsExtended';

// This hook is used to set values for all options on PDP
const useSetPdpAllSelectedOptions = () => {
  const setPdpSelectedOptionsExtended = useSetPdpSelectedOptionsExtended();

  const setPdpAllSelectedOptions = useCallback(
    updatedPdpSelectedOptionsFull => {
      setPdpSelectedOptionsExtended(currentPdpSelectedOptionsExtended => {
        const updatedPdpSelectedOptionsExtended = getExtendedSelectedOptionsFromFullFormat(
          updatedPdpSelectedOptionsFull
        );

        // If the selected options are the same, don't update the state
        if (
          isEqual(
            currentPdpSelectedOptionsExtended,
            updatedPdpSelectedOptionsExtended
          )
        ) {
          return currentPdpSelectedOptionsExtended;
        }

        return updatedPdpSelectedOptionsExtended;
      });
    },
    [setPdpSelectedOptionsExtended]
  );

  return setPdpAllSelectedOptions;
};

export default useSetPdpAllSelectedOptions;
