import React from 'react';
import PropTypes from 'prop-types';
import ProgressLoaderDots from 'components/essentials/ProgressLoaderDots/ProgressLoaderDots';

const LoadMoreButton = ({
  'aria-label': ariaLabel = 'Load more items',
  label = 'Load more',
  onClick = () => {},
  loading = false,
}) => (
  <div className="relative flex items-center flex-col justify-center mt-6 mx-0 mb-4 md:mb-1">
    {loading ? (
      <div className="px-0 py-4">
        <ProgressLoaderDots />
      </div>
    ) : (
      <button
        type="button"
        className="text-sm bg-transparent border border-solid border-gray-light5 rounded-sm text-gray cursor-pointer px-0 py-3 text-center uppercase w-[200px]"
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {label}
      </button>
    )}
  </div>
);

LoadMoreButton.propTypes = {
  'aria-label': PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default LoadMoreButton;
