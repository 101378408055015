// Create an object that contains all data and setter functions that are needed
// to render a ProductCard for the selected option and layout.
const mergeProductAndSelectedOptionData = ({
  campaign,
  id,
  moreVariantsCount,
  name,
  onDisplayInShowroomSlug,
  path: productPath,
  productFamily,
  productLabel,
  quickship,
  secondaryImage,
  secondaryImageCropData,
  selectedOption,
  slug,
  variantsToShow,
  variantsTotal,
}) => {
  // Extract all required data from the selected option
  const {
    image,
    isOutOfStock,
    label,
    otherAttributes,
    minPrice,
    originalPrice,
    path,
    price,
    sku,
    thumbnail,
  } = selectedOption ?? {};

  // Merge product data and selected option data into a single object
  const productCardData = {
    campaign,
    id,
    image,
    isOutOfStock,
    label,
    moreVariantsCount,
    name,
    onDisplayInShowroomSlug,
    minPrice,
    originalPrice,
    otherAttributes,
    path,
    price,
    productFamily,
    productLabel,
    productPath,
    quickship,
    secondaryImage,
    secondaryImageCropData,
    sku,
    slug,
    upholsteryThumbnail: thumbnail,
    variantsToShow: variantsToShow.map(variant => ({
      ...variant,
      isSelected: variant.id === selectedOption.id,
    })),
    variantsTotal,
  };

  return productCardData;
};

export default mergeProductAndSelectedOptionData;
