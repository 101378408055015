import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isSearchOpenAtom from './isSearchOpenAtom';

const useCloseSearch = () => {
  const setIsSearchOpen = useSetAtom(isSearchOpenAtom);

  const closeSearch = useCallback(() => {
    setIsSearchOpen(false);
  }, [setIsSearchOpen]);

  return closeSearch;
};

export default useCloseSearch;
