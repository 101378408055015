import React from 'react';
import PropTypes from 'prop-types';

import JBProductImage from 'components/essentials/JBImage/JBProductImage';
import ProductCardImageComingSoon from './ProductCardImageComingSoon';

const ProductCardStudioImage = ({ cropData, image, isMobile }) => {
  if (!image) {
    return <ProductCardImageComingSoon />;
  }

  const imageWidthAndHeight = isMobile ? 265 : 341;

  return (
    <JBProductImage
      ar="1:1"
      className="w-full h-full"
      crop={!cropData ? 'center' : undefined}
      cropFormat="1x1"
      crops={cropData}
      enableCrops
      height={imageWidthAndHeight}
      lazy
      src={image}
      width={imageWidthAndHeight}
    />
  );
};

ProductCardStudioImage.propTypes = {
  cropData: PropTypes.object,
  image: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default ProductCardStudioImage;
