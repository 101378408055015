import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import useAffirmScript from 'hooks/useAffirmScript';
import refreshFinancingContent from 'commons/refreshFinancingContent';
import CtaButton from 'components/essentials/Cta/CtaButton';

const FinancingCtaButton = React.forwardRef(
  ({ children, color, style, styling }, ref) => {
    const affirmButtonRef = useRef();

    useAffirmScript({
      onLoad: refreshFinancingContent,
    });

    useEffect(() => {
      refreshFinancingContent();
    }, []);

    const handleFinancingClick = useCallback(() => {
      affirmButtonRef.current?.click();
    }, []);

    return (
      <>
        <CtaButton
          color={color}
          onClick={handleFinancingClick}
          ref={ref}
          style={style}
          styling={styling}
          type="button"
        >
          {children}
        </CtaButton>

        <span
          ref={affirmButtonRef}
          className="hidden affirm-site-modal"
          data-page-type="landing"
        />
      </>
    );
  }
);

FinancingCtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  styling: PropTypes.string,
};

export default FinancingCtaButton;
