import { useEffect } from 'react';

const useOnClick = (ref, onClick) => {
  useEffect(() => {
    const node = ref.current;

    if (node && onClick) {
      node.addEventListener('click', onClick);

      return () => {
        node.removeEventListener('click', onClick);
      };
    }

    return () => {};
  }, [onClick, ref]);
};

export default useOnClick;
