import React from 'react';
import PropTypes from 'prop-types';
import IconClose from 'components/icons/iconClose';

const MoveItButton = ({
  className = '',
  icon: Icon = IconClose,
  isCloseButton = true,
  onClick,
}) => (
  <button
    aria-label={isCloseButton ? 'Close' : undefined}
    className={`items-center bg-white border border-solid border-gray-light6 rounded-[48px] text-gray cursor-pointer flex h-12 justify-center w-12 z-[1] hover:border-brand [&_svg_path]:fill-gray ${className}`}
    onClick={onClick}
    title={isCloseButton ? 'Close' : undefined}
    type="button"
  >
    <Icon />
  </button>
);

MoveItButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  isCloseButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MoveItButton;
