import { camelCase } from 'lodash';
import queryString from 'query-string';

// please add all the option names and screen names here
const validKeys = [
  'bedSize',
  'fabric',
  'frame',
  'leather',
  'legOption',
  'mattress',
  'optionalCushion',
  'orientation',
  'pillows',
  'rugs',
  'size',
  'wood',
  'woodStain',
];

export const removeDeepLinkURLParams = searchStr => {
  const deepLinkURLParams = queryString.parse(searchStr);
  validKeys.forEach(key => {
    // check if this key is a selected option
    delete deepLinkURLParams[key];
  });

  return queryString.stringify(deepLinkURLParams);
};

export const getPDPCustomizerURLParams = location => {
  const deepLinkURLParams = queryString.parse(location.search);
  const finalURLParams = {};

  for (const key of Object.keys(deepLinkURLParams)) {
    const normalizedKey = camelCase(key).replace('rugs', 'size');
    if (validKeys.indexOf(normalizedKey) !== -1) {
      finalURLParams[normalizedKey] = deepLinkURLParams[key];
    }
  }

  return Object.keys(finalURLParams).length === 0 ? null : finalURLParams;
};

export const fabricOptionInReadyToShip = (
  productOptionsStock,
  urlParamsRaw
) => {
  if (!urlParamsRaw?.fabric) {
    return null;
  }

  const urlParams = {
    // can be used later for adding new keys
    fabric: urlParamsRaw?.fabric,
  };

  let selectedOptionsObject = {};

  productOptionsStock.forEach(productOption => {
    const productOptionScreenName = productOption?.name
      ?.replace(/[_-\s]/g, '')
      ?.toLowerCase();
    const productOptionName =
      productOption?.original_name?.replace(/[_-\s]/g, '')?.toLowerCase() || '';
    Object.keys(urlParams).forEach(urlParamKey => {
      const urlOptionName = urlParamKey?.replace(/[_-\s]/g, '')?.toLowerCase();
      if (
        productOptionScreenName === urlOptionName ||
        productOptionName === urlOptionName
      ) {
        const newOptionValueObj = productOption?.values?.filter(
          value =>
            value.value &&
            value.value?.replace(/[_-\s]/g, '')?.toLowerCase() ===
              urlParams[urlParamKey]?.replace?.(/[_-\s]/g, '')?.toLowerCase?.()
        )?.[0];
        if (newOptionValueObj) {
          selectedOptionsObject = {
            ...selectedOptionsObject,
            [productOption.id]: newOptionValueObj.id,
          };
        }
      }
    });
  });

  return Object.keys(selectedOptionsObject).length === 0
    ? null
    : selectedOptionsObject;
};

export const getSelectionsObj = (productOptions, urlParams) => {
  let selectedOptionsObject = {};

  productOptions.forEach(productOption => {
    const productOptionScreenName = productOption.name
      .replace(/[_-\s]/g, '')
      .toLowerCase();

    const productOptionName =
      productOption?.original_name?.replace(/[_-\s]/g, '')?.toLowerCase() || '';

    Object.keys(urlParams).forEach(urlParamKey => {
      const urlOptionName = urlParamKey.replace(/[_-\s]/g, '')?.toLowerCase();

      if (
        productOptionScreenName === urlOptionName ||
        productOptionName === urlOptionName
      ) {
        const newOptionValueObj = productOption?.values?.filter(
          value =>
            value?.value &&
            value?.value?.replace(/[_-\s]/g, '')?.toLowerCase() ===
              urlParams[urlParamKey]?.replace?.(/[_-\s]/g, '')?.toLowerCase?.()
        )?.[0];

        if (newOptionValueObj) {
          selectedOptionsObject = {
            ...selectedOptionsObject,
            [productOption.id]: newOptionValueObj.id,
          };
        }
      }
    });
  });

  return Object.keys(selectedOptionsObject).length === 0
    ? null
    : selectedOptionsObject;
};
