import { isEmpty } from 'lodash';
import { findOptionAndTypeFromSkuV2 } from 'commons/productUtils';

const ORIENTATION_OPTION_IDS = [
  16,
  120,
  70,
  74,
  85,
  89,
  117,
  119,
  122,
  123,
  136,
  153,
  154,
  162,
];

export const hasOrientationType = id => {
  const idVal = parseInt(id, 10);
  return ORIENTATION_OPTION_IDS.includes(idVal);
};

export const isUpholsteryOption = id => {
  const idVal = parseInt(id, 10);
  return idVal === 17 || idVal === 18;
};

export const findDefaultOptionValue = option => {
  let defaultId = option.default_value;
  if (!defaultId) {
    defaultId = option.values?.[0].id;
  }
  return defaultId;
};

// FIXME: Replace this with field from Backbone or Admin
export const OPTION_DISPLAY_MAP = {
  Fabric: 'ImageSelector',
  'Wood Stain': 'ImageSelector',
  Leather: 'ImageSelector',
  Wood: 'ImageSelector',
  Rugs: 'TextDropdownSelector',
  Orientation: 'ImageAndTextSelector',
  'Bed Size': 'TextSelector',
  'Orientation Sectional Bumper': 'ImageAndTextSelector',
  'Leg Option': 'ImageAndTextSelector',
  Mattress: 'TextDropdownSelector',
  Pillows: 'TextDropdownSelector',
  'Orientation Single Arm Chaise': 'ImageAndTextSelector',
  'Orientation L': 'ImageAndTextSelector',
  'Reversible Sectional Bonus Cushion': 'UpsellSelector',
  'Optional Cushion': 'ExtraOptionSelector',
  'Orientation Bumper Chaise': 'ImageAndTextSelector',
  'Orientation Display Single Arm Chair': 'ImageAndTextSelector',
  Size: 'TextDropdownSelector',
  'Sofa Width': 'TextDropdownSelector',
  Frame: 'ImageAndTextSelector',
  'Chaise Orientation': 'ImageAndTextSelector',
  'Single Arm Chair Orientation': 'ImageAndTextSelector',
  'Single Arm Chaise Orientation': 'ImageAndTextSelector',
  'Orientation U Sofa Bumper Sectional': 'ImageAndTextSelector',
  'Orientation Display Single Arm Chaise': 'ImageAndTextSelector',
  'Vendor Leather': 'ImageSelector',
  'Orientation Sleeper': 'ImageAndTextSelector',
  'Orientation Louie': 'ImageAndTextSelector',
};

export const convertProductOptionsToUsableFormat = (
  productOptionsData,
  optionValueDetails
) => {
  const {
    productOptions: { options },
  } = productOptionsData;
  const finalOptions = options.map(option => {
    const { values, popular_values, ...otherProps } = option;
    const [{ option_values }] = values;
    const optionDisplayType =
      OPTION_DISPLAY_MAP[option.name] || 'TextDropdownSelector';
    const finalValues = option_values.map(optionValue => {
      const popularOrder = popular_values.indexOf(optionValue.id);
      return {
        ...optionValueDetails[optionValue.id],
        ...optionValue,
        popular: +popularOrder !== -1,
        popularOrder,
      };
    });
    return { ...otherProps, optionDisplayType, values: finalValues };
  });
  return finalOptions;
};

export const getDefaultSelectedOptions = (
  productOptions,
  urlOverrides,
  flashSaleVariation = {}
) => {
  const newSelections = {};
  // eslint-disable-next-line no-unused-vars
  for (const option of productOptions) {
    if (newSelections[option.id]) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const finalDefaultValue =
      !isEmpty(flashSaleVariation) && flashSaleVariation[option.id]
        ? flashSaleVariation[option.id][0]
        : option.default_value;
    newSelections[option.id] =
      option.values.find(({ id }) => `${id}` === `${finalDefaultValue}`) ||
      option.values?.[0];
  }
  if (urlOverrides) {
    // eslint-disable-next-line no-unused-vars
    for (const option of productOptions) {
      Object.keys(urlOverrides).forEach(urlOverrideKey => {
        if (option.id.toString() === urlOverrideKey) {
          const overrideVal = option.values.filter(
            value => value.id === urlOverrides[urlOverrideKey]
          )[0];
          if (overrideVal) {
            newSelections[option.id] = overrideVal;
          }
        }
      });
    }
  }
  return newSelections;
};

export const getOptionsOrder = options =>
  options ? options.map(opt => opt.id) : [];

export const isSelectedOptionsValid = (selectedOptions = {}, options) => {
  const selectedOptionIds = Object.keys(selectedOptions);
  if (selectedOptionIds?.length !== options?.length) {
    return false;
  }
  // eslint-disable-next-line no-unused-vars
  for (const optionId of Object.keys(selectedOptions)) {
    const option = options.find(({ id }) => `${optionId}` === `${id}`);
    if (!option) {
      return false;
    }
    const value = option.values?.find(
      ({ id }) => `${id}` === `${selectedOptions[optionId]}`
    );
    if (!value) {
      return false;
    }
  }
  return true;
};

export const getSelectedOptionsForVariantSku = (variantSku, productOptions) => {
  if (!variantSku || !productOptions?.length) {
    return {};
  }

  const skuParts = variantSku.split('-').filter(Boolean);
  const selectedOptions = {};

  productOptions.forEach(option => {
    const defaultValue =
      option.values.find(({ id }) => `${id}` === `${option.default_value}`) ||
      option.values?.[0];
    selectedOptions[option.id] = defaultValue?.id;
  });

  skuParts.forEach(partSku => {
    const [finalOptionValue, optionTypeId] = findOptionAndTypeFromSkuV2({
      options: productOptions,
      partSku,
    });

    if (optionTypeId && finalOptionValue?.id) {
      selectedOptions[optionTypeId] = finalOptionValue.id;
    }
  });

  return selectedOptions;
};
