import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { RemoveScroll } from 'react-remove-scroll';

import useOnEsc from 'hooks/useOnEsc';
import useTrapFocus from 'hooks/useTrapFocus';
import useRandomId from 'hooks/useRandomId';
import getOrCreateElementById from 'commons/getOrCreateElementById';
import MobileModal from 'components/consumer/MobileModal';
import MoveItButton from 'components/consumer/MoveItButton';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import useFocusOnOpen from 'hooks/useFocusOnOpen';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useScreen from 'hooks/useScreen';

const sidebarModalRootElement = getOrCreateElementById('SidebarModal-root');

const SidebarModal = ({
  children,
  isOpen = false,
  lockScrollWhenOpen = true,
  mobileCloseIcon,
  mobileContent,
  mobileContentUnderHeader = null,
  mobileHeaderCenter,
  mobileHeaderRight,
  mobileIsFullHeight = false,
  mobileNoPadding = false,
  mobileScrollingHeader,
  mobileStickyFooter,
  mobileHideHeader,
  desktopNoCloseIcon = false,
  desktopNoPadding = false,
  desktopNoScroll = false,
  desktopWidthSize,
  onClose,
  title,
  transparentOverlay = false,
}) => {
  const { isMobile } = useScreen();

  const modalRef = useFocusOnOpen(isOpen);
  const modalTitleId = useRandomId('SidebarModal-title');

  useOnEsc(isOpen, onClose);
  useTrapFocus(isOpen, modalRef);

  if (isMobile) {
    return (
      <MobileModal
        {...{
          children: mobileContent || children,
          closeIcon: mobileCloseIcon,
          contentUnderHeader: mobileContentUnderHeader,
          headerCenter: mobileHeaderCenter,
          headerRight: mobileHeaderRight,
          hideHeader: mobileHideHeader,
          isFullHeight: mobileIsFullHeight,
          isOpen,
          lockScrollWhenOpen,
          noPadding: mobileContentUnderHeader || mobileNoPadding,
          onClose,
          scrollingHeader: mobileScrollingHeader,
          stickyFooter: mobileStickyFooter,
          title,
        }}
      />
    );
  }

  return createPortal(
    <Transition
      show={isOpen}
      className={classNames('group', {
        'pointer-events-none': !isOpen,
      })}
    >
      <div className="SidebarModal">
        <Transition.Child
          aria-hidden
          className={classNames(
            'fixed bottom-0 top-0 left-0 right-0 bg-blackOverlay group-[:not(:first-child)]:![background:none]',
            {
              '![background:none]': transparentOverlay,
            }
          )}
          onClick={onClose}
          tabIndex="-1"
          appear
          unmount
          enter="transition-opacity duration-500 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500 ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />

        <Transition.Child
          className={classNames(
            'bg-gray-light9 cursor-default h-screen fixed right-0 top-0',
            {
              'border-l-2 border-solid border-gray-light7': transparentOverlay,
            }
          )}
          appear
          unmount
          enter="transition-[opacity,transform] duration-500 ease-in-out"
          enterFrom="opacity-0 translate-x-[100vw]"
          enterTo="translate-x-0 opacity-100"
          leave="transition-[opacity,transform] duration-500 ease-in-out"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="opacity-0 translate-x-[calc(100vw_-_4rem)]"
        >
          <RemoveScroll
            className={classNames('h-screen overflow-x-hidden', {
              'pt-[4.5rem] px-8 pb-8': !desktopNoPadding,
              'p-0': desktopNoPadding,
              'overflow-y-auto': !desktopNoScroll,
              'bg-white': desktopNoScroll,
              'w-[448px]': desktopWidthSize !== 'lg',
              'w-[600px]': desktopWidthSize === 'lg',
            })}
            removeScrollBar={false}
            enabled={lockScrollWhenOpen}
          >
            <div ref={modalRef} role="dialog" aria-labelledby={modalTitleId}>
              <VisuallyHidden>Dialog content starts</VisuallyHidden>

              {!desktopNoCloseIcon && (
                <MoveItButton
                  className="absolute right-4 top-4 bg-white"
                  onClick={onClose}
                />
              )}
              {title && (
                <h2
                  className="text-xl font-bold text-gray mt-0 mx-0 mb-2"
                  id={modalTitleId}
                >
                  {title}
                </h2>
              )}
              {children}

              <VisuallyHidden>Dialog content ends</VisuallyHidden>
            </div>
          </RemoveScroll>
        </Transition.Child>
      </div>
    </Transition>,
    sidebarModalRootElement
  );
};

SidebarModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  lockScrollWhenOpen: PropTypes.bool,
  mobileCloseIcon: PropTypes.node,
  mobileContent: PropTypes.node,
  mobileContentUnderHeader: PropTypes.bool,
  mobileHeaderCenter: PropTypes.node,
  mobileHeaderRight: PropTypes.node,
  mobileHideHeader: PropTypes.bool,
  mobileIsFullHeight: PropTypes.bool,
  mobileNoPadding: PropTypes.bool,
  mobileScrollingHeader: PropTypes.node,
  mobileStickyFooter: PropTypes.node,
  desktopWidthSize: PropTypes.oneOf(['lg']),
  desktopNoPadding: PropTypes.bool,
  desktopNoScroll: PropTypes.bool,
  desktopNoCloseIcon: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  transparentOverlay: PropTypes.bool,
};

export default withBrowserOnly(SidebarModal);
