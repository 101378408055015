const formatter = new Intl.DateTimeFormat('en-us', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

const getFormattedDate = dateString => {
  if (!dateString) {
    return '';
  }

  const dateObject = new Date(dateString);
  const formattedDate = formatter.format(dateObject);

  return formattedDate;
};

export default getFormattedDate;
