import React from 'react';
import PropTypes from 'prop-types';

import TogglePill from '../TogglePill';

const TogglePillGroup = ({
  className = '',
  items = [],
  selectedItem,
  selectedItems = [], // if multiple items need to be selected.
  bindValues = false, // this is used to send back the group name and option name that has been selected by user.
  groupName = '', // this is the group name for the toggle pill group .For eg : Material or Color etc
  variant,
}) => (
  <ul className={`flex flex-wrap list-none m-0 ${className}`}>
    {items.map(
      ({
        ariaLabel,
        id,
        label,
        isLoading,
        isSelected,
        isDisabled,
        onClick,
      }) => (
        <li key={id}>
          <TogglePill
            aria-label={ariaLabel}
            isDisabled={isDisabled}
            key={label}
            isSelected={
              isSelected || selectedItem === id || selectedItems.includes(id)
            }
            onClick={
              !isLoading && !isDisabled
                ? bindValues
                  ? () => onClick(groupName, id)
                  : onClick
                : null
            }
            variant={variant}
          >
            {label}
          </TogglePill>
        </li>
      )
    )}
  </ul>
);

TogglePillGroup.propTypes = {
  bindValues: PropTypes.bool,
  className: PropTypes.string,
  groupName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
  selectedItem: PropTypes.string,
  selectedItems: PropTypes.array,
  variant: PropTypes.oneOf(['ShopByHeaderSection']),
};

export default TogglePillGroup;
