import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StatusLabel = ({ children, className = '', color = 'gray' }) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(
        'text-xs font-bold rounded-sm inline-block px-2 py-1',
        className,
        {
          'bg-yellow-light text-gray': color === 'gray',
          'bg-brand-lightest text-brand-darker': color === 'brand',
          'bg-red text-white': color === 'red-dark',
        }
      )}
    >
      {children}
    </div>
  );
};

StatusLabel.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['gray', 'brand', 'red-dark']),
};

export default StatusLabel;
