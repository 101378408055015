import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/essentials/Modal';
import classNames from 'classnames';

import useTrapFocus from 'hooks/useTrapFocus';
import useRandomId from 'hooks/useRandomId';
import useFocusOnOpen from 'hooks/useFocusOnOpen';
import {
  colorWhite,
  colorBrandDark,
  colorGray,
  colorGrayOverlay,
} from 'styles/layout/colors';
import IconClose from 'components/icons/iconClose';
import CtaButton from 'components/essentials/Cta/CtaButton';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useScreen from 'hooks/useScreen';

const DESKTOP_MAX_WIDTH = '90vw';
const DESKTOP_MAX_HEIGHT = '90vh';
const MOBILE_MAX_WIDTH = '100vw';
const MOBILE_MAX_HEIGHT = '100vh';

const getModalWidth = ({ isMobile, size }) => {
  if (isMobile) return MOBILE_MAX_WIDTH;
  if (size === 'xl') return DESKTOP_MAX_WIDTH;

  return undefined;
};

const getModalHeight = ({ isMobile, size }) => {
  if (isMobile) return MOBILE_MAX_HEIGHT;
  if (size === 'xl') return DESKTOP_MAX_HEIGHT;

  return undefined;
};

const getTheme = (themeName = 'brand') =>
  themeName === 'brand'
    ? {
        backgroundColor: colorBrandDark,
        backgroundImage:
          'url(https://joybird2.imgix.net/user-uploads-staging/joybird-teal-1587161610709.jpeg?auto=format,compress&cs=srgb)',
        color: colorWhite,
      }
    : {
        backgroundColor: colorWhite,
        color: colorGray,
      };

const StandardModal = ({
  children,
  closeIconColor = 'currentColor',
  icon,
  isOpen,
  onClose,
  showOkButtonOnMobile = true,
  size = 'sm',
  textSize = 'md',
  theme = 'brand',
  title,
  titleAlignment = 'left',
}) => {
  const { isMobile } = useScreen();

  const modalRef = useFocusOnOpen(isOpen);
  const modalTitleId = useRandomId('StandardModal-title');
  useTrapFocus(isOpen, modalRef);

  const modalTheme = getTheme(theme);

  return (
    <Modal
      open={isOpen}
      center
      closeOnOverlayClick
      closeOnEsc
      shouldCloseOnOverlayClick
      onClose={onClose}
      classNames={{
        closeButton: 'hidden',
      }}
      styles={{
        modal: {
          borderRadius: !isMobile ? 8 : 0,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: modalTheme.backgroundColor,
          backgroundImage: modalTheme.backgroundImage,
          backgroundSize: 'cover',
          color: modalTheme.color,
          padding: 0,
          width: getModalWidth({ isMobile, size }),
          height: getModalHeight({ isMobile, size }),
          maxHeight: isMobile ? MOBILE_MAX_HEIGHT : DESKTOP_MAX_HEIGHT,
          maxWidth: isMobile ? MOBILE_MAX_WIDTH : DESKTOP_MAX_WIDTH,
        },
        overlay: {
          backgroundColor: colorGrayOverlay,
          zIndex: 1006,
          padding: isMobile ? 0 : '1.2rem',
        },
        closeButton: {
          display: 'none',
        },
      }}
    >
      <div
        aria-labelledby={modalTitleId}
        className="max-[767px]:mt-12 max-sm:mt-20"
        ref={modalRef}
        role="dialog"
      >
        <button
          aria-label="Close"
          className={classNames(
            'group/StandardModal__closeButton bg-transparent border-0 cursor-pointer absolute top-3.5 max-sm:top-7 max-[767px]:right-3.5 md:right-6',
            {
              'md:right-3.5': size === 'md' || size === 'lg',
              'focus-visible:outline-brand-light': theme !== 'light',
              'focus-visible:outline-gray-light3': theme === 'light',
            }
          )}
          onClick={onClose}
          title="Close"
          type="button"
        >
          <div
            className={classNames(
              'items-center flex h-[35px] justify-center w-[35px]',
              {
                'text-brand-light': theme !== 'light',
                'text-gray': theme === 'light',
                'group-hover/StandardModal__closeButton:text-white':
                  theme !== 'light',
                'group-hover/StandardModal__closeButton:text-gray-dark':
                  theme === 'light',
                'group-focus/StandardModal__closeButton:text-white':
                  theme !== 'light',
                'group-focus/StandardModal__closeButton:text-gray-dark':
                  theme === 'light',
              }
            )}
          >
            <IconClose color={closeIconColor} height={21} width={21} />
          </div>
        </button>

        <div
          className={classNames(
            'mx-auto my-0 max-h-[90vh] max-w-[90vw] overflow-y-auto p-5 md:px-8',
            {
              'md:w-[85vw]': size !== 'xl',
              'md:max-w-[570px] md:pt-8 md:pb-12': size === 'sm',
              'md:pt-12 md:pb-8': size === 'md' || size === 'lg',
              'md:max-w-[900px] max-[1023px]:!px-0': size === 'md',
              'md:max-w-[1156px]': size === 'lg',
              'md:h-[90vh] md:w-[90vw] md:py-8': size === 'xl',
            }
          )}
        >
          {(!!title || !!icon) && (
            <div
              className={classNames('flex flex-row gap-2 mb-3 items-center', {
                'lg:justify-center': titleAlignment === 'center',
              })}
            >
              {!!icon && <div className="max-[767px]:hidden">{icon}</div>}

              {!!title && (
                <h2
                  className={classNames(
                    'font-bold m-0',
                    '[&_svg]:h-[30px] [&_svg]:ml-2.5',
                    {
                      'text-2xl': textSize !== 'sm',
                      'text-lg': textSize === 'sm',
                      'md:pr-12': size === 'sm',
                      'text-white': theme !== 'light',
                      'text-brand-darker': theme === 'light',
                    }
                  )}
                  id={modalTitleId}
                >
                  {title}
                </h2>
              )}
            </div>
          )}

          <div
            className={classNames('font-bold', {
              'text-base': textSize !== 'sm',
              'text-sm': textSize === 'sm',
              'md:pr-12': size === 'sm',
              'text-brand-lightest': theme !== 'light',
              'text-gray': theme === 'light',
            })}
          >
            {children}
          </div>

          {showOkButtonOnMobile && (
            <CtaButton
              aria-label="Close"
              onClick={onClose}
              data-xc="close-mobile-ksp-modal"
              type="button"
              color="brand-light"
              block
              className="mt-8 md:!hidden"
            >
              Okay
            </CtaButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

StandardModal.propTypes = {
  children: PropTypes.node,
  closeIconColor: PropTypes.string,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showOkButtonOnMobile: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  textSize: PropTypes.oneOf(['sm', 'md']),
  theme: PropTypes.oneOf(['brand', 'light']),
  title: PropTypes.string,
  titleAlignment: PropTypes.oneOf(['center', 'left']),
};

export default withBrowserOnly(StandardModal);
