import { useContext } from 'react';

import ProductCardContext from 'data-hooks/context/ProductCardContext';

const useProductCardContext = () => {
  const productCardData = useContext(ProductCardContext);
  return productCardData;
};

export default useProductCardContext;
