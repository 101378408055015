import { useMemo } from 'react';

import {
  findDefaultOptionValue,
  hasOrientationType,
} from 'components/consumer/PDP/helpers/pdpOptionUtils';

const usePDPOptionsOrientationIds = ({ productOptions }) =>
  useMemo(() => {
    if (!productOptions) {
      return [];
    }

    const orientationOptionId = productOptions
      .map(({ id: sId }) => sId)
      .find(hasOrientationType);

    const orientationOptionValueId = orientationOptionId
      ? findDefaultOptionValue(
          productOptions.find(
            opt => parseInt(opt.id, 10) === parseInt(orientationOptionId, 10)
          )
        )
      : null;

    return {
      orientationOptionId,
      orientationOptionValueId,
    };
  }, [productOptions]);

export default usePDPOptionsOrientationIds;
