import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PDPActiveFabric from 'components/consumer/PDP/PDPActiveFabric';
import Placeholder from 'components/consumer/Placeholder';

const ProductCardOptionsUI = ({
  loading = false,
  fabricSelectedOption,
  productOptions = [],
}) => {
  if (loading) {
    return (
      <Placeholder type="rect" loading style={{ height: 16, width: 172 }} />
    );
  }

  return (
    <div
      className={classNames(
        'pt-4 flex md:flex-row md:items-center flex-col items-start',
        {}
      )}
    >
      {!!fabricSelectedOption && (
        <PDPActiveFabric
          selectedOption={fabricSelectedOption}
          showInfoIcon={false}
          size="xs"
        />
      )}

      <div className="ProductCardOptionsUI__options">
        {productOptions.map(option => (
          <div className="items-center flex pl-0.5 pb-1.5" key={option}>
            <div className="bg-yellow-light rounded-sm h-3 mr-2.5 w-3" />

            <div className="text-xs text-gray flex font-bold">{option}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

ProductCardOptionsUI.propTypes = {
  fabricSelectedOption: PropTypes.object,
  loading: PropTypes.bool,
  productOptions: PropTypes.array,
};

export default ProductCardOptionsUI;
