import { cloneDeep, isEmpty } from 'lodash';
import { findOptionAndTypeFromSkuV2 } from 'commons/productUtils';
import {
  isUpholsteryOption,
  OPTION_DISPLAY_MAP,
} from '../../components/consumer/PDP/helpers/pdpOptionUtils';

const findOptionForId = ({ options, optionId }) => {
  if (!options?.length) {
    return null;
  }
  return options.find(
    optionValue => parseInt(optionValue.id, 10) === parseInt(optionId, 10)
  );
};

const getInitialStockHolderObject = ({ options, optionTypeId, optionName }) => {
  let finalStockObject = {};
  const optionObj = {
    ...(findOptionForId({ options, optionId: optionTypeId }) || {}),
  };
  finalStockObject = cloneDeep(optionObj);
  finalStockObject.values = [];
  finalStockObject.optionDisplayType =
    OPTION_DISPLAY_MAP[optionName] || 'TextDropdownSelector';

  return { ...finalStockObject };
};

const isOptionInValues = ({ values, optionIdToCheck }) => {
  const optIndex = values.findIndex(optVal => +optVal.id === +optionIdToCheck);

  return optIndex >= 0;
};

const getOptionNameFromId = ({ optionId, options }) => {
  if (!options?.length) {
    return '';
  }
  const option = findOptionForId({ options, optionId });
  return option?.name || '';
};

/* Returns transformed stock data
*   - stockOptions <Array> : All options in stock in the useProductOptions options format
*   - stockOptionsCombined <Array> : Returns 
*        - { stockSku : Full sku of stock, 
            stockOptionIds: {optionId, optionValueId} for each sku, 
            stockQty : Stock quantity, 
            stockSource: Stock source (default, local)
            activeFallbackOptionValue: Fallback active option values to be set when switching from inactive
          }
    - instockOptionIds <Array>: All option value Ids in stock
*/

export const stockSkuToOptions = (
  stockData,
  options = [],
  allOptionValues = {},
  stockVariant
) => {
  if (!stockData || !options) {
    return [[], [], []];
  }
  // If no options, simply return stock quantity
  if (!options.length) {
    return [stockData[''], [], []];
  }
  // If options, map sku to IDs
  let skus = Object.keys(stockData);
  if (stockVariant && stockData[stockVariant]) {
    const idx = skus.indexOf(stockVariant);
    skus.splice(idx, 1);
    skus = [stockVariant, ...skus];
  }
  let qsData = [];
  const qsDataIds = [];
  const defaultOpts = [];
  const localOpts = [];
  const stockOptionsObject = {};
  let upholsteryOptionId = null;
  const inActiveOptionValueIds = [];
  const activeValsForEachOption = {};
  skus.forEach(skuVal => {
    const fullStockOptions = {
      stockSku: skuVal,
      stockOptionIds: [],
      stockQty: stockData[skuVal].stock,
      stockSource: stockData[skuVal].stockSource || 'default',
      activeFallbackOptionValue: {},
    };
    let optionSelection = {};
    let stockOption = null;
    let optionTypeId = null;
    let optionName = null;
    const skusInStock = skuVal.split('-').filter(v => !!v);
    const tempVariantId = Math.floor(Math.random() + 100) + 1; // Temporary variant id for inactive options generated for each product

    if (skusInStock.length && parseInt(stockData[skuVal].stock, 10) > 0) {
      skusInStock.forEach(partSku => {
        [stockOption, optionTypeId, optionName] = findOptionAndTypeFromSkuV2({
          partSku,
          options,
        });
        if (stockOption && optionTypeId && optionName) {
          const optionKey = `${optionTypeId}-key`;
          if (!upholsteryOptionId && isUpholsteryOption(optionTypeId)) {
            upholsteryOptionId = optionTypeId;
          }
          // Initialise stock options type
          if (!stockOptionsObject[optionKey]) {
            stockOptionsObject[optionKey] = getInitialStockHolderObject({
              options,
              optionTypeId,
              optionName,
            });
          }
          if (
            !isOptionInValues({
              values: stockOptionsObject[optionKey].values,
              optionIdToCheck: stockOption.id,
            })
          ) {
            const finalOptionObj = {
              ...stockOption,
              ...allOptionValues[stockOption.id],
            };
            stockOptionsObject[optionKey].values.push(finalOptionObj);
            if (!activeValsForEachOption[optionTypeId]) {
              activeValsForEachOption[optionTypeId] = { ...finalOptionObj };
            }
          }

          qsDataIds.push(stockOption.id);
          fullStockOptions.stockOptionIds.push({
            optionId: optionTypeId,
            optionValueId: stockOption.id,
          });
          optionSelection = {
            ...optionSelection,
            [optionTypeId]: stockOption.id,
          };
        } else if (
          !Array.isArray(stockData[skuVal].options) &&
          stockData[skuVal].options[`-${partSku}`] &&
          stockData[skuVal].options[`-${partSku}`].optionValue &&
          parseInt(
            stockData[skuVal].options[`-${partSku}`].optionValue.id,
            10
          ) === parseInt(stockData[skuVal].options[`-${partSku}`].id, 10)
        ) {
          const inactiveCollectionTypeId =
            stockData[skuVal].options[`-${partSku}`].optionValue.fk_option_id;
          const optionKey = `${inactiveCollectionTypeId}-key`;
          const { optionValue } = stockData[skuVal].options[`-${partSku}`];
          if (!stockOptionsObject[optionKey]) {
            stockOptionsObject[optionKey] = getInitialStockHolderObject({
              options,
              optionTypeId: inactiveCollectionTypeId,
              optionName: getOptionNameFromId({
                optionId: inactiveCollectionTypeId,
                options,
              }),
            });

            if (!activeValsForEachOption[inactiveCollectionTypeId]) {
              const optionObj = findOptionForId({
                options,
                optionId: inactiveCollectionTypeId,
              });
              activeValsForEachOption[inactiveCollectionTypeId] = !optionObj
                ? {}
                : optionObj.values.find(oVal => !!oVal?.is_active);
            }
          }
          if (
            !fullStockOptions.activeFallbackOptionValue[
              inactiveCollectionTypeId
            ]
          ) {
            fullStockOptions.activeFallbackOptionValue[
              inactiveCollectionTypeId
            ] = activeValsForEachOption[inactiveCollectionTypeId];
          }

          if (
            !isOptionInValues({
              values: stockOptionsObject[optionKey].values,
              optionIdToCheck: optionValue.id,
            })
          ) {
            const valueFromAllOpts = allOptionValues[optionValue.id] || {};
            stockOptionsObject[optionKey].values.push({
              ...optionValue,
              is_active: 0,
              variant_id: tempVariantId,
              popular: false,
              price_type: '',
              additional_visibility: 0,
              ...valueFromAllOpts,
              group: {
                ...optionValue.group,
                images: null,
              },
            });
            fullStockOptions.stockOptionIds.push({
              optionId: inactiveCollectionTypeId,
              optionValueId: optionValue.id,
            });
            inActiveOptionValueIds.push(optionValue.id);
            optionSelection = {
              ...optionSelection,
              [inactiveCollectionTypeId]:
                stockData[skuVal].options[`-${partSku}`].optionValue.id,
            };
            qsDataIds.push(
              stockData[skuVal].options[`-${partSku}`].optionValue.id
            );
          } else {
            fullStockOptions.stockOptionIds.push({
              optionId: inactiveCollectionTypeId,
              optionValueId: optionValue.id,
            });
            // Inactive value already in option values
            optionSelection = {
              ...optionSelection,
              [inactiveCollectionTypeId]:
                stockData[skuVal].options[`-${partSku}`].optionValue.id,
            };
          }
        }
      });
    }

    const selKeys = Object.keys(optionSelection);
    const isValidStockOption =
      selKeys.length && selKeys.length === options.length;

    if (!isEmpty(fullStockOptions.stockOptionIds) && isValidStockOption) {
      if (fullStockOptions.stockSource === 'local') {
        localOpts.push(fullStockOptions);
      } else {
        defaultOpts.push(fullStockOptions);
      }
    }
  });
  qsData = localOpts.concat(defaultOpts);

  const finalStockOptions = Object.keys(stockOptionsObject).map(
    optionId => stockOptionsObject[optionId]
  );
  if (!upholsteryOptionId && options?.length) {
    // Pick first option in list
    upholsteryOptionId = options[0].id;
  }
  return [
    finalStockOptions,
    qsData,
    qsDataIds,
    upholsteryOptionId,
    inActiveOptionValueIds,
  ];
};

export const getCombinedOptionSKUs = (
  selectedOptionsVariantSku,
  stockOptionsCombined,
) => {
  if (!selectedOptionsVariantSku || !stockOptionsCombined?.length) {
    return [];
  }
  return stockOptionsCombined.map(stockOption => stockOption.stockSku);
};

const findOptionValue = (optionId, valueId, stockOptions) =>
  stockOptions
    .find(({ id }) => id === optionId)
    .values.find(({ id }) => id === valueId);

export const findEnabledSelectedOptions = ({
  selectedOptions,
  stockOptionsCombined,
  stockOptions,
  upholsteryOptionId,
  inActiveOptionValueIds = [],
  onlyActive = false,
}) => {
  const updatedSelectedOptions = { ...selectedOptions };
  const updatedSelectedOptionsFull = {};
  const primarySelectedOptionValueId = selectedOptions[upholsteryOptionId];
  let hasInactive = false;
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const stockOptObj of stockOptionsCombined) {
    const matchedOpt = stockOptObj.stockOptionIds.find(
      optObj =>
        optObj.optionId === parseInt(upholsteryOptionId, 10) &&
        optObj.optionValueId === primarySelectedOptionValueId
    );
    if (matchedOpt) {
      // Map selection to this combination
      // eslint-disable-next-line no-loop-func
      stockOptObj.stockOptionIds.forEach(optIdObj => {
        if (inActiveOptionValueIds.includes(optIdObj.optionValueId)) {
          hasInactive = true;
        }
        const isActiveOnly =
          hasInactive &&
          onlyActive &&
          stockOptObj.activeFallbackOptionValue?.[optIdObj.optionId];

        updatedSelectedOptionsFull[optIdObj.optionId] = isActiveOnly
          ? stockOptObj.activeFallbackOptionValue[optIdObj.optionId]
          : findOptionValue(
              optIdObj.optionId,
              optIdObj.optionValueId,
              stockOptions
            );
        updatedSelectedOptions[optIdObj.optionId] = isActiveOnly
          ? stockOptObj.activeFallbackOptionValue[optIdObj.optionId].id
          : optIdObj.optionValueId;
      });
      break;
    }
  }
  return [updatedSelectedOptions, updatedSelectedOptionsFull, hasInactive];
};

export const updateSelectedOptionsToFirstInStock = ({
  upholsteryOptionId,
  stockOptions,
  stockOptionsCombined,
  inActiveOptionValueIds = [],
}) => {
  if (
    !upholsteryOptionId ||
    !stockOptions?.length ||
    !stockOptionsCombined?.length
  ) {
    return [];
  }

  let hasInactive = false;
  const updatedSelectedOptions = {};
  const updatedSelectedOptionsFull = {};
  const matchedStockOpt = stockOptionsCombined[0]; // Pick first in stock option
  if (matchedStockOpt) {
    // Map selection to this combination
    matchedStockOpt.stockOptionIds.forEach(optIdObj => {
      if (inActiveOptionValueIds.includes(optIdObj.optionValueId)) {
        hasInactive = true;
      }
      updatedSelectedOptionsFull[optIdObj.optionId] = findOptionValue(
        optIdObj.optionId,
        optIdObj.optionValueId,
        stockOptions
      );
      updatedSelectedOptions[optIdObj.optionId] = optIdObj.optionValueId;
    });
  }
  return [updatedSelectedOptions, updatedSelectedOptionsFull, hasInactive];
};
