import React, { useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import ReactPrefetcher from 'react-prefetcher';
import PropTypes from 'prop-types';
import queue from 'async/queue';
import isClient from 'commons/isClient';
import history from 'history-events';
import { logError } from 'commons/logger';
import JBImage from '../JBImage';
import JBProductImage from '../JBImage/JBProductImage';

function loadImage({ url, imgixProps, isProductImage }, onLoadCallback) {
  if (!imgixProps || !isClient()) {
    return;
  }

  const ImageComponent = isProductImage ? JBProductImage : JBImage;

  if (!isClient()) {
    onLoadCallback();
    return;
  }

  const tempDiv = document.createElement('div');
  const root = createRoot(tempDiv);

  root.render(
    <ImageComponent
      src={url}
      lazy={false}
      {...imgixProps}
      forPage="pdp"
      onLoad={(...args) => {
        setTimeout(() => {
          root.unmount();
        }, 500);
        onLoadCallback(...args);
      }}
    />
  );
}

// A queue to do the prefetch requests with limited concurency(3) to not clog the network with prefetch requests
const imageQueue = queue((task, callback) => {
  try {
    loadImage(task, (...args) => {
      try {
        callback(...args);
      } catch (err) {
        // ignore;
      }
    });
  } catch (err) {
    logError(new Error('Error in prefetcher', { cause: err }));
  }
}, 3);

// Clear the queue when the user visits a new page
if (isClient() && history.isHistorySupported()) {
  window.addEventListener('changestate', () => {
    imageQueue.remove(() => true);
  });
}

const Prefetcher = ({
  onRenderAssets,
  onHoverAssets,
  onClickAssets,
  onImgixLoadError,
  ...props
}) => {
  const prefetchImgixImage = useCallback(
    asset => {
      if (!asset) {
        return null;
      }
      return imageQueue.push(asset, isLoadingError => {
        if (isLoadingError) {
          onImgixLoadError?.(asset?.url);
        }
      });
    },
    [onImgixLoadError]
  );

  const getAssetValue = useCallback(
    asset => {
      if (!asset.imgixProps) {
        return asset.url;
      }

      return {
        href: JSON.stringify(asset),
        fetcher: () => prefetchImgixImage(asset),
      };
    },
    [prefetchImgixImage]
  );

  return (
    <ReactPrefetcher
      onRenderAssets={onRenderAssets && onRenderAssets.map(getAssetValue)}
      onHoverAssets={onHoverAssets && onHoverAssets.map(getAssetValue)}
      onClickAssets={onClickAssets && onClickAssets.map(getAssetValue)}
      {...props}
    />
  );
};

const assetType = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      imgixProps: PropTypes.object,
    }),
    PropTypes.string,
  ])
);

Prefetcher.propTypes = {
  onRenderAssets: assetType,
  onHoverAssets: assetType,
  onClickAssets: assetType,
  onImgixLoadError: PropTypes.func,
};

export default Prefetcher;
