// This function receives a selectedOptionsArray such as this:
// [
//   {
//     "17": 3537,
//     "19": 757
//   },
//   {
//     "17": 2129
//   },
//   {
//     "17": 4751
//   }
// ]
// Then merges these into a single object, where the selected option in every
// later object overwrites the previous one, but **only** if the specified
// option ID and value ID is present in the productOptions array.
const mergeSelectedOptions = ({
  productOptions,
  selectedOptionsArray = [],
}) => {
  const allSelectedOptions = selectedOptionsArray.flatMap(selectedOptions =>
    Object.entries(selectedOptions || [])
  );

  return allSelectedOptions.reduce((acc, [optionId, valueId]) => {
    const option = productOptions.find(({ id }) => +id === +optionId);

    if (option) {
      const value = option.values.find(({ id }) => +id === +valueId);

      if (value) {
        return {
          ...acc,
          [optionId]: +valueId,
        };
      }
    }

    return acc;
  }, {});
};

export default mergeSelectedOptions;
