import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VisuallyHidden from 'components/consumer/VisuallyHidden';

const TogglePill = ({
  'aria-label': ariaLabel,
  children,
  isDisabled,
  isSelected,
  onClick,
  variant,
}) => (
  <button
    aria-label={ariaLabel}
    className={classNames(
      'text-sm font-bold rounded-[30px] inline-block mt-1 mb-1 ml-0 px-4 border-[1px] focus-visible:outline-offset-4',
      {
        'bg-white border-gray-light6 text-gray': !isSelected,
        'bg-brand border-brand text-white': isSelected,
        'hover:bg-white hover:border-brand text-gray':
          !isSelected && !isDisabled,
        'cursor-pointer': !isDisabled,
        'cursor-not-allowed opacity-50': isDisabled,
        'mr-2 py-2.5': variant !== 'ShopByHeaderSection',
        'mr-1 py-[.7rem]': variant === 'ShopByHeaderSection',
      }
    )}
    onClick={onClick}
    type="button"
  >
    {children}
    {!!isSelected && <VisuallyHidden>- selected</VisuallyHidden>}
  </button>
);

TogglePill.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['ShopByHeaderSection']),
};

export default TogglePill;
