/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';

import useRandomId from 'hooks/useRandomId';
import LabeledFieldWrapper from '../LabeledFieldWrapper';

const MaskedInput = loadable(() => import('react-text-mask'));

const LabeledTextInput = React.forwardRef(
  (
    {
      'aria-describedby': ariaDescribedBy = '',
      'data-xc': dataXc = 'LabeledTextInput',
      action,
      actionAlignment = 'right',
      actionHasPadding,
      actionStretchHeight = false,
      autoComplete,
      backgroundColor = 'white',
      className = '',
      component,
      disabled,
      errorMessage,
      htmlRef = null,
      id: customInputId,
      inputClassName = '',
      label,
      labelSize = 'md',
      mask,
      name,
      noMargin,
      onBlur,
      onChange,
      placeholder = '',
      postfixText,
      prefixText,
      prefixWidth,
      readOnly,
      type = 'text',
      value,
      variant = 'default',
      ...rest
    },
    ref
  ) => {
    const randomInputId = useRandomId('LabeledTextInput__input');
    const id = customInputId || randomInputId;

    const wrapperProps = {
      action,
      actionAlignment,
      actionHasPadding,
      actionStretchHeight,
      backgroundColor,
      className: classNames(
        `[&_input]:text-lg [&_input]:font-bold [&_input]:bg-transparent [&_input]:border-0 [&_input]:text-[inherit] [&_input]:min-h-fit [&_input]:h-[23px] [&_input]:w-full [&_input]:outline-0 [&_input]:disabled:opacity-100 ${className}`,
        {
          // If there is no value or placeholder, stretch the input to fill the
          // wrapper, to make it easy for the user to click into the input.
          '[&:not(:focus-within)_input]:bottom-0 [&:not(:focus-within)_input]:h-full [&:not(:focus-within)_input]:left-0 [&:not(:focus-within)_input]:pt-0 [&:not(:focus-within)_input]:absolute [&:not(:focus-within)_input]:right-0 [&:not(:focus-within)_input]:top-0':
            !value && !placeholder,
        }
      ),
      disabled,
      errorMessage,
      hasValue: !!value,
      label,
      labelSize,
      inputId: id,
      hasPlaceholder: !!placeholder,
      noMargin,
      placeholder,
      prefixText,
      prefixWidth,
      postfixText,
      variant,
    };

    const inputProps = {
      'aria-describedby': `${id}-error ${ariaDescribedBy}`,
      'aria-invalid': !!errorMessage || undefined,
      autoComplete,
      className: `text-lg font-bold bg-transparent border-0 text-[inherit] min-h-fit h-[23px] w-full outline-0 ${inputClassName}`,
      'data-xc': dataXc,
      disabled,
      id,
      name,
      onBlur,
      onChange,
      placeholder,
      type,
      value,
      ...rest,
    };

    if (component) {
      return (
        <LabeledFieldWrapper {...wrapperProps}>
          {React.createElement(component, {
            ...inputProps,
            htmlRef,
            placeholder: '',
            ref,
          })}
        </LabeledFieldWrapper>
      );
    }

    if (mask) {
      return (
        <LabeledFieldWrapper {...wrapperProps}>
          <MaskedInput {...inputProps} mask={mask} guide={false} ref={ref} />
        </LabeledFieldWrapper>
      );
    }

    return (
      <LabeledFieldWrapper {...wrapperProps}>
        <input autoCorrect="off" {...inputProps} ref={ref} />
      </LabeledFieldWrapper>
    );
  }
);

LabeledTextInput.propTypes = {
  'aria-describedby': PropTypes.string,
  'data-xc': PropTypes.string,
  action: PropTypes.element,
  actionAlignment: PropTypes.oneOf(['left', 'right']),
  actionHasPadding: PropTypes.bool,
  actionStretchHeight: PropTypes.bool,
  autoComplete: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['white', 'gray']),
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  htmlRef: PropTypes.object,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelSize: PropTypes.oneOf(['sm', 'md']),
  mask: PropTypes.array,
  name: PropTypes.string,
  noMargin: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefixText: PropTypes.node,
  postfixText: PropTypes.node,
  prefixWidth: PropTypes.number,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'livestream-search', 'range-filter']),
};

export default LabeledTextInput;
