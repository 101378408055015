import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProductCardNameUI = ({
  isHotspotCard = false,
  isModularSofasPage = false,
  name,
}) => (
  <h3
    className={classNames(
      'text-xs font-bold text-gray mt-2 mx-0 mb-0 lg:mt-3.5 xl:text-sm xl:tracking-[.0187rem] xl:font-bold xl:leading-[1.2rem] xl:mb-0.5',
      {
        'md:!mt-2': isModularSofasPage,
        'lg:!mt-0': isHotspotCard,
      }
    )}
    data-name="top-left-product-img-description"
    data-xc="top-left-product-img-description"
  >
    {name}
  </h3>
);

ProductCardNameUI.propTypes = {
  isHotspotCard: PropTypes.bool,
  isModularSofasPage: PropTypes.bool,
  name: PropTypes.string,
};

export default ProductCardNameUI;
