// Input format:
/*
{
  "17": {
    "value": {
      "id": 4621,
      "value": "Crave Irish Cream",
      "sku": "-CF491"
    }
  },
  "19": {
    "value": {
      "id": 757,
      "value": "Mocha",
      "sku": "-WS03"
    }
  }
}
*/

// Output format:
/*
{
  "17": 4621,
  "19": 757
}
*/

const getSelectedOptionsFromExtendedFormat = selectedOptionsExtended =>
  Object.entries(selectedOptionsExtended).reduce(
    (acc, [optionId, optionValueWrapper]) => {
      const optionValue = optionValueWrapper?.value;

      if (optionValue) {
        return {
          ...acc,
          [optionId]: optionValue.id,
        };
      }

      return acc;
    },
    {}
  );

export default getSelectedOptionsFromExtendedFormat;
