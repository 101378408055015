import constructImageURLsForVariant from './constructImageURLsForVariant';
import constructHeroImageURL from './constructHeroImageURL';
import getDefaultOptionData from './getDefaultOptionData';
import categoryAngles from './category-angles.json';

const getProductImageUrl = product => {
  let categoryImages = {};
  if (!product) return categoryImages;
  const productType = product.type ? product.type.name : '';
  const productAngle =
    productType && categoryAngles[productType.toLowerCase()]
      ? categoryAngles[productType.toLowerCase()]
      : 1;
  const herocategoryImages = constructHeroImageURL(
    product,
    null,
    productAngle,
    true
  );

  const { finalSku, optionName } = getDefaultOptionData(product);
  categoryImages = {
    ...herocategoryImages,
    ...constructImageURLsForVariant(
      product.id,
      finalSku,
      product.name,
      optionName,
      productAngle,
      null,
      true
    ),
  };
  return categoryImages;
};

export default getProductImageUrl;
