import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_PROTECTION_PLAN_TYPES } from 'mocks/queries';

// Get production plan types (for all products)
const useAllProtectionPlanTypes = () => {
  const { data: getProtectionPlanTypes } = useSuspenseQuery(
    GET_PROTECTION_PLAN_TYPES
  );

  return getProtectionPlanTypes?.getProtectionPlanTypes?.plans;
};

export default useAllProtectionPlanTypes;
