import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconErrorTriangle = ({
  color = colorGray,
  height = 32,
  width = 32,
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8525 12.7136V18.3811C16.8525 18.8224 16.495 19.1811 16.0525 19.1811C15.61 19.1811 15.2525 18.8224 15.2525 18.3811V12.7136C15.2525 12.2723 15.61 11.9136 16.0525 11.9136C16.495 11.9136 16.8525 12.2723 16.8525 12.7136ZM16.6187 21.4499C16.7712 21.5937 16.8512 21.8012 16.8512 22.0186C16.8512 22.2261 16.7637 22.4337 16.6187 22.5786C16.4675 22.7299 16.2675 22.8186 16.0512 22.8186C15.8437 22.8186 15.635 22.7299 15.4825 22.5786C15.34 22.4336 15.2513 22.2261 15.2513 22.0186C15.2513 21.8011 15.3313 21.6011 15.4825 21.4499C15.7875 21.1462 16.3312 21.1537 16.6188 21.4499H16.6187ZM25.9951 25.5786H6.10997C5.22997 25.5786 4.86997 24.9548 5.30997 24.1923L15.2524 6.97119C15.4724 6.59119 15.7624 6.39993 16.0524 6.39993C16.3423 6.39993 16.6324 6.59118 16.8524 6.97119L26.7948 24.1923C27.2348 24.9548 26.8748 25.5786 25.9948 25.5786H25.9951ZM28.181 23.3923L18.2373 6.17119C17.7348 5.29993 16.9373 4.79993 16.0523 4.79993C15.1661 4.79993 14.3698 5.29993 13.8661 6.17119L3.92369 23.3923C3.4212 24.2636 3.38619 25.2036 3.82869 25.9711C4.27119 26.7386 5.1037 27.1786 6.10994 27.1786H25.9951C27.0013 27.1786 27.8326 26.7386 28.2751 25.9711C28.7188 25.2036 28.6838 24.2636 28.1813 23.3923"
      fill={color}
    />
  </svg>
);

IconErrorTriangle.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default IconErrorTriangle;
