// Input format:
/*
{
  "17": {
    "description": "Obsessed with looped, boucle fabrics? Bloke may be right up your alley with its mixture of thick and thin textured yarn, which provides a unique boucle look and feel. The exaggerated size and texture created by the yarn make this fabric a real showstopper.",
    "pet_friendly": 0,
    "price_type": null,
    "color": "Yellow",
    "is_active": 1,
    "id": 4562,
    "value": "Bloke Goldenrod",
    "image": "https://joybird2.imgix.net/option_images/swatches/290x290-Bloke-Goldenrod-T1-019.jpg",
    "cover": "https://joybird2.imgix.net/option_images/swatches/Bloke-Goldenrod-T1-019.jpg",
    "sku": "-CF477",
    ...
  },
  "19": {
    "pet_friendly": 0,
    "price_type": null,
    "color": "",
    "is_active": 1,
    "group": null,
    "id": 757,
    "value": "Mocha",
    "image": "https://joybird2.imgix.net/option_images/swatches/290x290-_MG_1195.jpg",
    "cover": "https://joybird2.imgix.net/option_images/swatches/165-walnut.jpg",
    "sku": "-WS03",
    ...
  },
  "135": {
    "pet_friendly": 0,
    "price_type": null,
    "color": "",
    "is_active": 1,
    "group": null,
    "id": 4210,
    "value": "Cone",
    "image": "https://joybird2.imgix.net/option_images/swatches/290x290-leg-cone.jpg",
    "cover": "https://joybird2.imgix.net/option_images/swatches/leg-cone.jpg",
    "sku": "-CNLGS",
    ...
  }
}
*/

// Output format:
/*
{
  "17": {
    "id": 4562,
    "value": "Bloke Goldenrod",
    "sku": "-CF477",
  },
  "19": {
    "id": 757,
    "value": "Mocha",
    "sku": "-WS03",
  },
  "135": {
    "id": 4210,
    "value": "Cone",
    "sku": "-CNLGS",
  }
}
*/

const getExtendedSelectedOptionsFromFullFormat = selectedOptionsFull =>
  Object.entries(selectedOptionsFull).reduce(
    (acc, [optionId, { id, sku, value }]) => ({
      ...acc,
      [optionId]: { value: { id, sku, value } },
    }),
    {}
  );

export default getExtendedSelectedOptionsFromFullFormat;
