import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntersection } from 'react-use';

import ProductCardPhotoSliderDots from './ProductCardPhotoSliderDots';

const ProductCardPhotoSliderItem = React.forwardRef(({ children }, ref) => (
  <div
    className="aspect-square flex items-center justify-center snap-start min-w-full"
    ref={ref}
  >
    {children}
  </div>
));

ProductCardPhotoSliderItem.propTypes = {
  children: PropTypes.node,
};

const ProductCardPhotoSlider = ({ image1, image2 }) => {
  const item2Ref = useRef();

  const item2Intersection = useIntersection(item2Ref, {
    threshold: 0.6,
  });

  const isItem2Intersecting = item2Intersection?.isIntersecting;
  const activeItem = isItem2Intersecting ? 1 : 0;

  return (
    <div className="relative">
      <div className="aspect-square overflow-x-scroll flex flex-row snap-x snap-mandatory [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
        <ProductCardPhotoSliderItem>{image1}</ProductCardPhotoSliderItem>
        <ProductCardPhotoSliderItem ref={item2Ref}>
          {image2}
        </ProductCardPhotoSliderItem>
      </div>

      <ProductCardPhotoSliderDots activeItem={activeItem} itemsCount={2} />
    </div>
  );
};

ProductCardPhotoSlider.propTypes = {
  image1: PropTypes.node,
  image2: PropTypes.node,
};

export default ProductCardPhotoSlider;
