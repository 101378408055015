import { useKey } from 'react-use';

import focusAndClick from '../helpers/focusAndClick';
import getFirstFocusableElement from '../helpers/getFirstFocusableElement';
import getFocusableElements from '../helpers/getFocusableElements';
import getLastFocusableElement from '../helpers/getLastFocusableElement';
import isActiveElement from '../helpers/isActiveElement';
import {
  isEndKey,
  isHomeKey,
  isLeftArrowKey,
  isRightArrowKey,
} from '../helpers/keys';

const useKeyboardControlForTabs = parentRef => {
  // If user presses right arrow while on the last focusable element, focus and
  // click on the first focusable element, otherwise focus on next focusable
  // element
  useKey(
    isRightArrowKey,
    event => {
      const firstFocusableElement = getFirstFocusableElement(parentRef.current);
      const lastFocusableElement = getLastFocusableElement(parentRef.current);
      const focusableElements = getFocusableElements(parentRef.current);

      // Only enable control if one of the elements is currently in focus

      if (
        focusableElements.length &&
        focusableElements.includes(document.activeElement)
      ) {
        event.preventDefault();

        if (firstFocusableElement && isActiveElement(lastFocusableElement)) {
          focusAndClick(firstFocusableElement);
        } else {
          const indexOfCurrentElement = focusableElements.findIndex(
            isActiveElement
          );
          const nextElement = focusableElements[indexOfCurrentElement + 1];

          if (nextElement) {
            focusAndClick(nextElement);
          }
        }
      }
    },
    { event: 'keydown' }
  );

  // If user presses left arrow while on the first focusable element, focus and
  // click on the last focusable element, otherwise focus on previous focusable
  // element
  useKey(
    isLeftArrowKey,
    event => {
      const firstFocusableElement = getFirstFocusableElement(parentRef.current);
      const lastFocusableElement = getLastFocusableElement(parentRef.current);
      const focusableElements = getFocusableElements(parentRef.current);

      // Only enable control if one of the elements is currently in focus

      if (
        focusableElements.length &&
        focusableElements.includes(document.activeElement)
      ) {
        event.preventDefault();

        if (lastFocusableElement && isActiveElement(firstFocusableElement)) {
          focusAndClick(lastFocusableElement);
        } else {
          const indexOfCurrentElement = focusableElements.findIndex(
            isActiveElement
          );
          const prevElement = focusableElements[indexOfCurrentElement - 1];

          if (prevElement) {
            focusAndClick(prevElement);
          }
        }
      }
    },
    { event: 'keydown' }
  );

  // When user presses Home key, if not already focused on the first element
  // then focus and click on it
  useKey(
    isHomeKey,
    event => {
      const firstFocusableElement = getFirstFocusableElement(parentRef.current);
      const focusableElements = getFocusableElements(parentRef.current);

      // Only enable control if one of the elements is currently in focus

      if (
        focusableElements.length &&
        focusableElements.includes(document.activeElement)
      ) {
        event.preventDefault();

        if (firstFocusableElement && !isActiveElement(firstFocusableElement)) {
          focusAndClick(firstFocusableElement);
        }
      }
    },
    { event: 'keydown' }
  );

  // When user presses End key, if not already focused on the last element then
  // focus and click on it
  useKey(
    isEndKey,
    event => {
      const lastFocusableElement = getLastFocusableElement(parentRef.current);
      const focusableElements = getFocusableElements(parentRef.current);

      // Only enable control if one of the elements is currently in focus

      if (
        focusableElements.length &&
        focusableElements.includes(document.activeElement)
      ) {
        event.preventDefault();

        if (lastFocusableElement && !isActiveElement(lastFocusableElement)) {
          focusAndClick(lastFocusableElement);
        }
      }
    },
    { event: 'keydown' }
  );
};

export default useKeyboardControlForTabs;
