import React from 'react';

import imageComingSoon from 'assets/icons/image-coming-soon.svg';

const ProductCardImageComingSoon = () => (
  <img
    alt=""
    className="absolute w-[80%] h-[80%] top-[10%] left-[10%]"
    src={imageComingSoon}
  />
);

export default ProductCardImageComingSoon;
