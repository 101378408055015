import roomWiseJson from 'components/consumer/consumer-header/roomWise.json';
import addLinkToBreadcrumbs from './addLinkToBreadcrumbs';

function getBreadcrumbsForPage(forPage = null) {
  const breadcrumbItems = { Home: [{ label: 'Furniture', link: '/shop' }] };

  roomWiseJson.category.forEach(parentCat => {
    const temp = {};
    temp.label = parentCat.breadCrumbTitle || parentCat.categoryTitle;
    temp.link = parentCat.breadCrumbLink || parentCat.link;
    breadcrumbItems[temp.label] = [temp];
    if (forPage) {
      addLinkToBreadcrumbs(temp, breadcrumbItems);
    }

    const subCat = parentCat.subcategory;
    // check child object
    if (subCat) {
      let subCat_temp = {};
      subCat_temp.label = subCat.breadCrumbTitle || subCat.categoryTitle;
      if (subCat_temp.label) {
        subCat_temp.link = subCat.breadCrumbLink || subCat.link;
        breadcrumbItems[subCat_temp.label] = [temp, subCat_temp];
        if (forPage) {
          addLinkToBreadcrumbs(subCat_temp, breadcrumbItems);
        }
      } else {
        subCat_temp = null;
      }

      subCat.forEach(subsubCat => {
        const subsubCat_temp = {};
        subsubCat_temp.label =
          subsubCat.breadCrumbTitle ||
          subsubCat.subcategoryTitle ||
          subsubCat.categoryTitle;
        subsubCat_temp.link = subsubCat.link;
        breadcrumbItems[subsubCat_temp.label] = subCat_temp
          ? [temp, subCat_temp, subsubCat_temp]
          : [temp, subsubCat_temp];
        if (forPage) {
          addLinkToBreadcrumbs(subsubCat_temp, breadcrumbItems);
        }

        if (subsubCat.subcategory) {
          subsubCat.subcategory.forEach(lastCat => {
            const lastCat_temp = {};
            lastCat_temp.label =
              lastCat.breadCrumbTitle || lastCat.subcategoryTitle;
            lastCat_temp.link = lastCat.link;
            breadcrumbItems[lastCat.subcategoryTitle] = [
              temp,
              subsubCat_temp,
              lastCat_temp,
            ];
            if (forPage) {
              addLinkToBreadcrumbs(lastCat_temp, breadcrumbItems);
            }
          });
        }
      });
    }
  });
  return breadcrumbItems;
}

export default getBreadcrumbsForPage;
