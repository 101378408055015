import usePdpAtom from '../usePDPAtom';

const DEFAULT_VALUE = {};

const usePdpSelectedOptionsExtendedAtom = () => {
  const pdpSelectedOptionsExtendedAtom = usePdpAtom(
    'pdpSelectedOptionsExtendedAtom',
    DEFAULT_VALUE
  );

  return pdpSelectedOptionsExtendedAtom;
};

export default usePdpSelectedOptionsExtendedAtom;
