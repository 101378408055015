import React from 'react';
import PropTypes from 'prop-types';

const IconQuality = ({ color = '#ffffff', ...props }) => (
  <svg width="55px" height="54px" viewBox="0 0 55 54" color={color} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1547.000000, -308.000000)" fill="currentColor">
        <g transform="translate(1544.000000, 305.000000)">
          <path d="M10.9962195,10.9958801 C21.493442,0.501345026 38.5104955,0.501345026 49.0052002,10.9960498 L49.0052002,10.9960498 L46.3535498,13.6477002 C37.3232545,4.61740497 22.680308,4.61740497 13.6477002,13.6477002 C4.61743326,22.6779672 4.61743326,37.3207828 13.6475305,46.3508801 C22.680308,55.381345 37.3232545,55.381345 46.3532782,46.3513214 C51.8521943,40.8501516 54.1525078,33.0777184 52.7034429,25.5801756 L52.7034429,25.5801756 L56.3853071,24.8685744 C58.0681554,33.5757257 55.394015,42.6112671 49.0052002,49.0027002 C38.5104955,59.497405 21.493442,59.497405 10.9960498,49.0027002 C0.501316738,38.5079672 0.501316738,21.4907828 10.9962195,10.9958801 Z M55.4217956,9.86658095 L58.0614544,12.530169 L29.3989544,40.935169 C28.6621918,41.6653127 27.4726016,41.6585352 26.7442063,40.920044 L26.7442063,40.920044 L15.7417063,29.765044 L18.4115437,27.131706 L28.0942501,36.9486195 L55.4217956,9.86658095 Z" />
        </g>
      </g>
    </g>
  </svg>
);

IconQuality.propTypes = {
  color: PropTypes.string,
};

export default IconQuality;
