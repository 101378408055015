import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickAway, useHoverDirty, usePrevious } from 'react-use';

import InfoIcon from 'components/icons/IconInfoNew';
import CloseIcon from 'components/icons/iconClose';
import useOnClick from 'hooks/useOnClick';
import useScreen from 'hooks/useScreen';
import BrowserOnly from '../BrowserOnly';

const defaultLeftOffset = -45;

const InfoTooltip = ({
  align = 'standard',
  arrowOffset = 0,
  children,
  className = '',
  closeButtonClassName,
  customIcon = null,
  direction = 'bottom',
  disableHoverOnContent = false,
  iconClassName = '',
  iconSize = 'small',
  inactiveOnMobile = false,
  isOpenDefault = false,
  leftOffsetOverride = null,
  mode = 'pdp',
  modifier = '',
  onTooltipToggle = null,
  openOnHover = true,
  parentRef,
  parentTitle = '',
  showAbove = false,
  showCloseButton = false,
  showInfoIcon = true,
  text = '',
  textColor = 'gray',
  variant,
  width,
  withArrow = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const { isMobile, isMobileOrTablet, windowWidth } = useScreen();
  const isMobileMode = isMobileOrTablet && inactiveOnMobile;

  const openTooltip = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeTooltip = useCallback(() => {
    setIsOpen(false);
  }, []);

  const previousIsOpen = usePrevious(isOpen);

  // If the tooltip is opened on mobile, close it after 5 seconds
  useEffect(() => {
    if (isMobile && !previousIsOpen && isOpen) {
      setTimeout(() => {
        closeTooltip();
      }, 5000);
    }
  }, [isMobile, previousIsOpen, isOpen, closeTooltip]);

  const [closeClicked, setCloseClicked] = useState(false);
  const tooltipContentRef = useRef(null);

  const getLeftOffset = useCallback(() => {
    if (tooltipContentRef.current && isOpen) {
      let newOffset = defaultLeftOffset;
      const { left, right } = tooltipContentRef.current.getBoundingClientRect();
      const modalDimensions = document
        .getElementsByClassName('react-responsive-modal-modal')[0]
        ?.getBoundingClientRect();
      const containerRight =
        mode === 'quickview' && !!modalDimensions
          ? modalDimensions.right
          : windowWidth;

      if (right + 16 > containerRight) {
        const rightOverflow = right - containerRight;
        newOffset = defaultLeftOffset - rightOverflow - 16;
      } 
      else if (left < 16) {
        newOffset = defaultLeftOffset + 16;
      }
      return newOffset;
    }
    return defaultLeftOffset;
  }, [isOpen, windowWidth, mode])

  const leftOffset = getLeftOffset();

  useEffect(() => {
    if (onTooltipToggle) {
      onTooltipToggle(isOpen, parentTitle);
    }
  }, [isOpen, onTooltipToggle, parentTitle]);

  const tooltipRef = useRef(null);

  // If parentRef is defined, use it for detecting hover & click events instead
  // of the info icon
  const hoverRef = parentRef || tooltipRef;

  useOnClick(hoverRef, openTooltip);
  useClickAway(hoverRef, closeTooltip);

  const isUserHoveringOverTooltip = useHoverDirty(hoverRef);

  useEffect(() => {
    if (tooltipContentRef.current && openOnHover) {
      if (isUserHoveringOverTooltip && !closeClicked) {
        openTooltip();
      } else if (!isMobileOrTablet) {
        closeTooltip();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeClicked, isMobileOrTablet, isUserHoveringOverTooltip, openOnHover]);

  const isBrandStyle = modifier === 'brand';
  const isBrandFadeStyle = modifier === 'brand-fade';

  return (
    <div
      className={classNames(
        'inline-block relative',
        {
          '!ml-[3px] [&_svg]:!w-2.5 [&_svg]:!h-2.5':
            isBrandStyle && isBrandFadeStyle,
          '[&_svg]:!mx-1 [&_svg]:!my-0': isBrandFadeStyle,
          'left-6 absolute top-3': align === 'left',
          '[&_svg]:!w-3.5 [&_svg]:!h-3.5 [&_svg]:mx-2 [&_svg]:my-0 [&_svg]:[filter:invert(22%)__sepia(4%)__saturate(0)__hue-rotate(55deg)__brightness(104%)__contrast(91%)]':
            iconSize === 'big',
          'ml-0': !!text,
          // ModularItem variant
          '!absolute !right-[3px] !top-[3px] !z-[2000]':
            variant === 'ModularItem',
          // PDPShownInUI variant
          '!ml-0': variant === 'PDPShownInUI',
        },
        className
      )}
      ref={tooltipRef}
    >
      {!!text && (
        <span
          className={classNames({
            'text-brand': textColor === 'brand',
            'text-gray': textColor === 'gray',
          })}
        >
          {text}
        </span>
      )}
      {!!showInfoIcon && !customIcon && (
        <InfoIcon
          className={`cursor-pointer h-3.5 relative top-px w-3.5 [&_g]:fill-brand ${iconClassName}`}
          onClick={() => {
            openTooltip();
          }}
        />
      )}
      {/* customIcon will always override infoIcon */}
      {!!customIcon && (
        <button
          type="button"
          className="inline"
          onClick={e => {
            e.stopPropagation();
            openTooltip();
          }}
        >
          {customIcon}
        </button>
      )}
      {withArrow && !isMobileMode ? (
        <div
          className={classNames(
            'absolute border-t-transparent border-x-transparent border-b-brand-dark border-solid [border-width:10px_12px] left-0 top-3 w-[25px]',
            {
              hidden: !isOpen && !isBrandFadeStyle,
              '!top-[unset] !bottom-[11px] !rotate-180': direction === 'top',
              '!left-2/4 translate-x-[-50%]': arrowOffset === 0,
              '!left-10': variant === 'PDPShownInUI',
              'pointer-events-none': disableHoverOnContent,
              '[transition:opacity_.5s_ease-in-out]': isBrandFadeStyle,
              'invisible opacity-0': isBrandFadeStyle && !isOpen,
              'visible opacity-100': isBrandFadeStyle && isOpen,
              '!border-t-brand-dark !border-x-transparent !border-b-transparent !top-auto bottom-[5px]': showAbove,
            }
          )}
          style={{
            left: arrowOffset,
          }}
        />
      ) : null}

      {!isMobileMode && (
        <BrowserOnly>
          <div
            className={classNames(
              'whitespace-pre-wrap bg-white border border-solid [border-color:#dbdbdb] rounded-lg left-[-45px] p-6 absolute top-5 w-[315px] z-[1]',
              'before:lg:[content:""] before:lg:cursor-pointer before:lg:h-5 before:lg:left-0 before:lg:absolute before:lg:right-0 before:lg:-top-5',
              {
                hidden: !isOpen && !isBrandFadeStyle,
                block: isOpen,
                '!top-[unset] !bottom-[30px]': direction === 'top',
                '!flex-row': variant === 'PDPCustomerReviews',
                'lg:!left-[-210px]': variant === 'PDPShownInUI',
                'pointer-events-none': disableHoverOnContent,
                // Brand style
                '[&_a]:!no-underline [&_a]:!text-white':
                  isBrandStyle || isBrandFadeStyle,
                '!bg-brand-dark !text-white !border-0 !rounded-sm !top-[29px]': isBrandStyle,
                // Brand fade style
                'cursor-pointer !bg-brand-dark ![background-image:url(https://joybird2.imgix.net/user-uploads-staging/joybird-teal-new.jpg?w=400&cs=srgb)] bg-cover text-white !border-0 !rounded-sm !top-[29px] flex flex-row-reverse justify-between items-start [transition:opacity_.5s_ease-in-out] z-[4000] max-[1023px]:cursor-pointer': isBrandFadeStyle,
                '[&_button]:cursor-pointer [&_button]:![background:none] [&_button]:!border-0 [&_button]:float-right': isBrandFadeStyle,
                'invisible opacity-0 !w-0': isBrandFadeStyle && !isOpen,
                'visible opacity-100': isBrandFadeStyle && isOpen,
                // Show above
                'bottom-[25px] !top-auto': showAbove,
                'before:lg:-bottom-5 before:lg:top-auto': showAbove,
              }
            )}
            ref={tooltipContentRef}
            style={{
              left: `${
                leftOffsetOverride !== null ? leftOffsetOverride : leftOffset
              }px`,
              width: !isBrandFadeStyle || isOpen ? width : undefined,
            }}
          >
            {showCloseButton || (!openOnHover && !isMobileOrTablet) ? (
              <div
                className={classNames(
                  'flex items-center mt-0 mx-0 mb-2 justify-between w-[15%] [&_svg_path]:fill-white',
                  closeButtonClassName,
                  {
                    '!justify-end': parentTitle === '' && isBrandFadeStyle,
                  }
                )}
              >
                {!!parentTitle && (
                  <h3 className="text-base font-bold mt-0 mb-0 ml-0 mr-2">
                    {parentTitle}
                  </h3>
                )}
                <button
                  onClick={event => {
                    event.stopPropagation();
                    setCloseClicked(true);
                    closeTooltip();
                  }}
                  type="button"
                >
                  <CloseIcon />
                </button>
              </div>
            ) : null}
            {children}
          </div>
        </BrowserOnly>
      )}
    </div>
  );
};

InfoTooltip.propTypes = {
  align: PropTypes.oneOf(['standard', 'left']),
  arrowOffset: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  customIcon: PropTypes.node,
  direction: PropTypes.oneOf(['bottom', 'top']),
  disableHoverOnContent: PropTypes.bool,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
  inactiveOnMobile: PropTypes.bool,
  isOpenDefault: PropTypes.bool,
  mode: PropTypes.oneOf(['quickview', 'pdp']),
  modifier: PropTypes.oneOf(['', 'brand', 'brand-fade']),
  onTooltipToggle: PropTypes.func,
  openOnHover: PropTypes.bool,
  parentRef: PropTypes.object,
  parentTitle: PropTypes.string,
  showAbove: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  text: PropTypes.string,
  textColor: PropTypes.oneOf(['brand', 'gray']),
  variant: PropTypes.oneOf([
    'ModularItem',
    'PDPCustomerReviews',
    'PDPShownInUI',
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withArrow: PropTypes.bool,
  leftOffsetOverride: PropTypes.number,
};

export default InfoTooltip;
