import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import SidebarModal from 'components/consumer/SidebarModal';
import SuccessMessage from 'components/consumer/SuccessMessage/SuccessMessage';
import withBrowserOnly from 'components/hoc/withBrowserOnly';

const SwatchRequestForm = loadable(() =>
  import('components/consumer/swatch-request-form')
);

const PDPOrderSwatchesSidebar = ({
  formLocation = 'pdp',
  onClose,
  orderSwatchesAsFirstLevel,
  show,
}) => {
  const [isOrderSwatchesSuccess, setIsOrderSwatchesSuccess] = useState(false);

  if (!show) return null;

  return (
    <SidebarModal isOpen={show} onClose={onClose} mobileContentUnderHeader>
      {isOrderSwatchesSuccess ? (
        <SuccessMessage
          onClick={onClose}
          orderSwatchesAsFirstLevel={orderSwatchesAsFirstLevel}
          text="Get excited - your swatches will be on their way soon!"
          bodyText={
            "Due to COVID-19, we're taking precautions in the production and shipment of swatches. Swatches will be shipped within 14 business days, but may take a little longer than usual to be delivered."
          }
          linkText="← Keep Customizing"
        />
      ) : (
        <div className="text-sm">
          <SwatchRequestForm
            hideMessageOnSuccess
            onSubmitSuccess={() => {
              setIsOrderSwatchesSuccess(true);
            }}
            formLocation={formLocation}
            inSidebar
          />

          <div className="mt-6 mx-0 mb-0 text-center">
            <button
              type="button"
              className="text-sm font-bold text-gray border-0 [background:none]"
              onClick={() => onClose(orderSwatchesAsFirstLevel)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </SidebarModal>
  );
};

PDPOrderSwatchesSidebar.propTypes = {
  formLocation: PropTypes.string,
  onClose: PropTypes.func,
  orderSwatchesAsFirstLevel: PropTypes.bool,
  show: PropTypes.bool,
};

export default withBrowserOnly(PDPOrderSwatchesSidebar);
