import useProductLocalStock from 'data-hooks/useProductLocalStock';

// Get stock data for this product
const usePDPLocalStock = ({ id, slug, productOptions }, { skip: skipAll }) => {
  const stock = useProductLocalStock({
    slug,
    id,
    options: productOptions,
    skipAll,
  });

  return stock;
};

export default usePDPLocalStock;
