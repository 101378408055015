import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import JBImage from 'components/essentials/JBImage';
import { logError } from 'commons/logger';
import publicImgBaseURL from 'commons/publicImgBaseURL';
import getProductImageUrl from 'commons/getProductImageUrl';
import getProductImageSrc from 'commons/getProductImageSrc';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import ReviewStars from 'components/consumer/ReviewStars';
import SanitizedHtml from 'components/consumer/SanitizedHtml';
import IconVerified from '../../../icons/iconVerified';

const maxWords = 75;

const getFullName = fullName => {
  if (!fullName) {
    return null;
  }
  const formattedName = fullName.split(' ').splice(0, 2);
  if (formattedName[1]) {
    formattedName[1] = formattedName[1].charAt(0);
  }
  return formattedName.join(' ');
};

class CustomerReviewCard extends React.Component {
  constructor(props) {
    super(props);
    const { productReview } = props;
    const collapsed = productReview.comment
      ? productReview.comment.split(' ').length > maxWords
      : false;
    this.state = {
      collapsed,
    };
  }

  printText(text) {
    if (this.state.collapsed) {
      return `${text
        .split(' ')
        .splice(0, maxWords)
        .join(' ')} ...`;
    }
    return text;
  }

  toggleText() {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  }

  render() {
    const { productReview, type, product } = this.props;
    const review = productReview.reviews
      ? productReview.reviews[0]
      : productReview;
    if (!review) {
      return null;
    }

    let date = '';

    try {
      if (review?.date) {
        date = format(new Date(review.date), 'MMM dd, yyyy');
      }
    } catch (e) {
      logError(e);
    }

    const productImages =
      type !== 'search' && product.options ? getProductImageUrl(product) : null;
    if (type === 'search') {
      const searchImg = review.image_link
        ? publicImgBaseURL(review.image_link)
        : null;
      return (
        <div
          key={review.review_id}
          className={classNames(
            'mx-5 md:mx-0 flex px-0 py-8 [border-bottom:1px_solid_#e5e5e5]',
            '[&_img]:object-contain', // might conflict with another css, need to check with product team
            {
              'flex-col min-[1440px]:flex-row max-[765px]:flex-col':
                this.props?.variant !== 'advanced-search',
              'flex-row max-[1023px]:flex-col':
                this.props?.variant === 'advanced-search',
            }
          )}
        >
          {review.image_link && (
            <div
              className={classNames('flex justify-center', {
                'items-center mr-4 max-[568px]:w-full w-20 max-[765px]:w-full':
                  this.props?.variant !== 'advanced-search',
                'items-start w-[200px] min-w-[200px] mr-10':
                  this.props?.variant === 'advanced-search',
              })}
            >
              <JBImage
                src={
                  productImages
                    ? productImages.transparent_hero_image_ts
                      ? productImages.transparent_hero_image_ts
                      : productImages.transparent_config_image_ts
                    : searchImg
                }
                className="max-w-[80%] max-h-[80%] object-cover md:max-h-full md:max-w-full"
                loader
                height={160}
                trim="color"
                trimPad={20}
                alt=""
              />
            </div>
          )}
          <div
            className={classNames(
              'items-start text-gray w-[70%] flex flex-col max-[765px]:w-full max-[765px]:p-0 max-[765px]:mt-4'
            )}
          >
            {review.sef_url && (
              <div className="[&_a]:text-[1.2rem] [&_a]:leading-[2rem] [&_a]:no-underline [&_a]:text-gray">
                <Link to={review.sef_url}> {review.product_name}</Link>
              </div>
            )}
            <div className="flex flex-col">
              {type === 'productReview' ? (
                <div className="text-[1.3rem] mb-0 font-bold leading-[1.5rem] text-gray">
                  {review.review_title}
                </div>
              ) : null}
              <div className="text-[.8rem] mb-2">{`By ${getFullName(
                review.review_full_name
              )} - ${date}`}</div>
              <div className="ml-[-.25rem] flex flex-row justify-items-center">
                {!!review?.review_rate && (
                  <VisuallyHidden>{review.review_rate} stars</VisuallyHidden>
                )}
                <ReviewStars
                  rating={parseInt(review.review_rate, 10)}
                  readOnly
                  size="sm"
                />
              </div>
            </div>
            <div className="text-base">
              <SanitizedHtml
                as="span"
                html={this.printText(review.review_comment)}
              />

              {this.state.collapsed && (
                <button
                  type="button"
                  className="mb-2 text-gray cursor-pointer hover:underline"
                  onClick={() => this.toggleText()}
                >
                  Continue Reading
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
    const img = productReview.product_image
      ? productReview.product_image
      : null;
    return type !== 'productReviews' ? (
      <div
        key={review.id}
        className={classNames(
          'mx-5 md:mx-0 flex px-0 py-8 [border-bottom:1px_solid_#e5e5e5]',
          '[&_img]:object-contain', // might conflict with another css, need to check with product team
          {
            'flex-col min-[1440px]:flex-row max-[765px]:flex-col':
              this.props?.variant !== 'advanced-search',
            'flex-row max-[1023px]:flex-col':
              this.props?.variant === 'advanced-search',
          }
        )}
      >
        <div className="text-base font-bold min-[1440px]:flex-[0_1_200px] min-[1440px]:mr-16">
          <div className="flex items-center mb-2 flex-wrap flex-[0_0_3.75rem]">
            <div className="w-[3.75rem] h-[3.75rem] max-h-[3.75rem] bg-gray-light8 rounded-full mr-4 text-2xl text-gray-light6 flex justify-center items-center">
              {review.full_name?.[0]}
            </div>
            <div className="flex-[1_1]">
              <div className="[&_svg]:ml-2 [&_g]:stroke-[#0eb9cb]">
                {getFullName(review.full_name)}
                <IconVerified />
              </div>
              <div className="font-normal">{review.location}</div>
            </div>
          </div>
          <div className="text-gray">Review left on {date}</div>
        </div>
        <div className="flex-[10_1]">
          <div className="mt-4 mx-0 mb-0 min-[1440px]:mt-0">
            <ReviewStars rating={review.rate} readOnly size="sm" />
          </div>

          {type === 'productReview' ? (
            <SanitizedHtml
              className="[&_a]:text-[1.2rem] [&_a]:leading-[2rem] [&_a]:no-underline [&_a]:text-gray"
              html={this.printText(review.title)}
            />
          ) : null}

          {type === 'allProducts' ? (
            <div className="text-[#b4b4b4] text-[.8rem] pl-[.8rem]">
              {productReview.total_reviews} Reviews
            </div>
          ) : null}

          <div className="text-base">
            <SanitizedHtml as="span" html={this.printText(review.comment)} />

            {this.state.collapsed && (
              <button
                type="button"
                className="mb-2 text-gray cursor-pointer hover:underline"
                onClick={() => this.toggleText()}
              >
                Continue Reading
              </button>
            )}
          </div>

          <div className="flex mt-6 text-[.9rem] [&_a]:text-gray">
            {productReview.product_image && (
              <div
                className={classNames('flex justify-center', {
                  'items-center mr-4 max-[568px]:w-full w-20 max-[765px]:w-full':
                    this.props?.variant !== 'advanced-search',
                  'items-start w-[200px] min-w-[200px] mr-10':
                    this.props?.variant === 'advanced-search',
                })}
              >
                <JBImage
                  src={
                    productImages
                      ? productImages.transparent_hero_image_ts
                        ? productImages.transparent_hero_image_ts
                        : productImages.transparent_config_image_ts
                      : img
                  }
                  fallbackSrc={getProductImageSrc(
                    { productImages, img },
                    product.options
                  )}
                  fallbackSrc2={img}
                  loader
                  height={60}
                  trim="color"
                  className="object-contain"
                  alt=""
                />
              </div>
            )}
            {productReview.product_url && (
              <div className="[&_a]:text-base [&_a]:font-bold [&_a]:text-[1.2rem] [&_a]:leading-[2rem] [&_a]:no-underline [&_a]:!text-brand">
                <Link to={productReview.product_url}>
                  {' '}
                  {productReview.product_name}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : (
      <React.Fragment key={review.id}>
        <div className="flex px-0 py-8 [border-bottom:1px_solid_#e5e5e5] flex-row min-[1440px]:flex-row max-[765px]:flex-col">
          <div className="max-[765px]:w-full max-[765px]:p-0 max-[765px]:mt-4">
            <div className="flex items-center mb-2 flex-wrap flex-[0_0_3.75rem]">
              <div className="w-11 h-11 max-h-[3.75rem] bg-[#eee] rounded-full mr-4 flex justify-center items-center" />
              <div>
                <div className="[&_svg]:ml-2 [&_g]:stroke-[#0eb9cb]">
                  <SanitizedHtml
                    as="span"
                    html={this.printText(review.full_name)}
                  />

                  <IconVerified />
                </div>
                <div className="text-sm font-normal">{review.location}</div>
              </div>
            </div>
            <div className="text-xs">Review left on {date}</div>
          </div>
          <div className="w-[70%] max-[765px]:w-full">
            <div>
              <ReviewStars rating={review.rate} readOnly size="sm" />
            </div>

            <SanitizedHtml html={this.printText(review.title)} />

            <div className="text-base">
              <SanitizedHtml as="span" html={this.printText(review.comment)} />

              {this.state.collapsed && (
                <button
                  type="button"
                  className="mb-2 text-gray cursor-pointer hover:underline"
                  onClick={() => this.toggleText()}
                >
                  Continue Reading
                </button>
              )}
            </div>
            <div className="flex mt-6 text-[.9rem] [&_a]:text-gray">
              {productReview.product_image && (
                <div className="mr-4 w-20 flex items-center justify-center max-[765px]:w-full">
                  <JBImage
                    src={
                      productImages
                        ? productImages.transparent_hero_image_ts
                          ? productImages.transparent_hero_image_ts
                          : productImages.transparent_config_image_ts
                        : img
                    }
                    fallbackSrc={getProductImageSrc(
                      { productImages, img },
                      product.options
                    )}
                    fallbackSrc2={img}
                    loader
                    height={60}
                    trim="color"
                  />
                </div>
              )}
              {productReview.product_url && (
                <div>
                  <Link to={productReview.product_url}>
                    {' '}
                    {productReview.product_name}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CustomerReviewCard.propTypes = {
  productReview: PropTypes.shape({
    comment: PropTypes.string,
    reviews: PropTypes.array,
    product_image: PropTypes.string,
    total_reviews: PropTypes.number,
    options: PropTypes.array,
    product_url: PropTypes.string,
    product_name: PropTypes.string,
  }),
  type: PropTypes.string,
  product: PropTypes.shape({
    options: PropTypes.array,
  }),
  variant: PropTypes.string,
};

export default CustomerReviewCard;
