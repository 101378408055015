import { useMemo } from 'react';

import { isUpholsteryOption } from 'components/consumer/PDP/helpers/pdpOptionUtils';

const usePDPOptionsHasSingleOption = ({ id, productOptions, slug }) =>
  useMemo(() => {
    if ((!id && !slug) || !productOptions) {
      return {
        hasSingleOptionType: false,
        hasSingleOptionValue: false,
      };
    }

    const upholsteryOptionId = (productOptions || [])
      .map(({ id: sId = 0 } = {}) => sId)
      .find(isUpholsteryOption);

    if (productOptions.length === 1) {
      if (upholsteryOptionId) {
        const upholsteryOption = productOptions.find(
          optValue => optValue.id === upholsteryOptionId
        );

        if (upholsteryOption?.values?.length === 1) {
          return {
            hasSingleOptionType: true,
            hasSingleOptionValue: true,
          };
        }
      } else {
        return {
          hasSingleOptionType: true,
          hasSingleOptionValue: false,
        };
      }
    }

    return {
      hasSingleOptionType: false,
      hasSingleOptionValue: false,
    };
  }, [id, slug, productOptions]);

export default usePDPOptionsHasSingleOption;
