import React from 'react';
import PropTypes from 'prop-types';

const VisuallyHidden = ({ children, className = '' }) => (
  <span className={`sr-only ${className}`}>{children}</span>
);

VisuallyHidden.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
};

export default VisuallyHidden;
