import useProductBasicDetails from 'data-hooks/useProductBasicDetails';
import useIsLoggedInCallback from 'data-hooks/useUserData/useIsLoggedInCallback';

const usePDPGetBasicDetails = ({ slug } = {}, { skip = false } = {}) => {
  const {
    data,
    error,
    // Get the raw loading state so that we can correctly derive the loading
    // property returned from this hook. We don't treat the data as loading
    // if it is already cached and can be immediately returned.
    rawLoading: loading,
    // Get the raw data property from Apollo so that we can detect if the
    // network request has completed but the basicDetails property is falsy,
    // which occurs when the product data cannot be found. We use this for the
    // isProductNotFound property returned from this hook.
    rawData,
    refetch,
  } = useProductBasicDetails(
    null,
    slug,
    {},
    {
      skip,
    }
  );

  // If user logs in or logs out, refetch to update the product prices
  useIsLoggedInCallback(refetch);

  return {
    data,
    error,
    loading: loading && !rawData,
    isProductNotFound: rawData && !data,
    refetch,
  };
};

export default usePDPGetBasicDetails;
