import React from 'react';
import StandardModal from 'components/consumer/StandardModal';
import avo from 'analytics/avo';
import PropTypes from 'prop-types';
import CtaLink from 'components/essentials/Cta/CtaLink';
import withBrowserOnly from 'components/hoc/withBrowserOnly';

const ProductCardShowroomModal = ({ data = {}, isOpen, onClose }) => {
  const showroomClicked = () => {
    avo.ctaClicked({
      text: data?.name,
      targetUrl: data?.url,
      cta: 'Retail Store',
      location: 'PDP',
    });
    window.open(data?.url, '_blank');
  };

  return (
    <StandardModal
      className="ProductCardShowroomModal"
      isOpen={isOpen}
      theme="light"
      onClose={onClose}
    >
      <h2 className="mb-8">{data?.name}</h2>
      <div className="flex justify-start items-start max-[767px]:flex-col">
        <div className="flex justify-start items-start flex-col">
          <h4 className="text-lg font-bold mb-4">Store Hours</h4>
          {data?.hours?.map((hourData, hourDataIndex) => (
            <p
              className="text-[.8125rem] tracking-[.0187rem] font-bold text-gray-light2 whitespace-nowrap leading-[1.4rem]"
              // It is safe to use index as key here because the data is static
              // for each showroom
              // eslint-disable-next-line react/no-array-index-key
              key={`${data?.name}-${hourDataIndex}`}
            >
              {hourData}
            </p>
          ))}
        </div>
        <div className="flex justify-start items-start flex-col pl-8 max-[767px]:pt-8 max-[767px]:px-0 max-[767px]:pb-0">
          <h4 className="text-lg font-bold mb-4">Location</h4>
          <a
            className="text-sm text-brand font-bold border-0 cursor-pointer bg-transparent no-underline"
            href={data?.locationUrl}
            target="_blank"
            rel="noreferrer"
          >
            {data?.location}
          </a>
          <CtaLink
            size="base"
            color="brand"
            styling="solid-button"
            to={data?.calendly}
            className="mx-0 my-[1.4rem]"
          >
            Book a Virtual Appointment
          </CtaLink>
          <button
            type="button"
            className="text-sm text-brand font-bold border-0 cursor-pointer bg-transparent no-underline"
            onClick={showroomClicked}
            aria-label="Browse products in Showroom"
          >
            Browse products on display here
          </button>
        </div>
      </div>
    </StandardModal>
  );
};

ProductCardShowroomModal.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default withBrowserOnly(ProductCardShowroomModal);
