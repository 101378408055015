import { useLocation } from 'react-router-dom';
import { useMemo, useRef } from 'react';
import { useRendersCount } from 'react-use';
import {
  getPDPCustomizerURLParams,
  getSelectionsObj,
} from 'components/consumer/PDP/helpers/pdpQueryParamUtils';
import isClient from 'commons/isClient';
import { isEmpty } from 'lodash';

const usePDPInitialOptionsFromQueryParams = ({ productOptions }) => {
  const location = useLocation();
  const rendersCount = useRendersCount();
  const urlParamsRef = useRef();

  // We only care about the initial URL params, after that we rely on state
  const selectedOptionsUrlParams = useMemo(() => {
    if (rendersCount === 1) {
      const currentUrlParams = getPDPCustomizerURLParams(
        isClient() ? window.location : location
      );
      urlParamsRef.current = currentUrlParams;

      return currentUrlParams;
    }

    return urlParamsRef.current;
  }, [location, rendersCount]);

  const selectedOptionsFromURLOverride = useMemo(() => {
    if (!selectedOptionsUrlParams || !productOptions) {
      return null;
    }

    const urlSelections = getSelectionsObj(
      productOptions,
      selectedOptionsUrlParams
    );

    if (!isEmpty(urlSelections)) {
      return urlSelections;
    }

    return null;
  }, [productOptions, selectedOptionsUrlParams]);

  return selectedOptionsFromURLOverride;
};

export default usePDPInitialOptionsFromQueryParams;
