/* This is being used in /reviews page. */
const getProductImageSrc = ({ productImages, image }, productOptions) => {
  if (!productOptions) {
    return productImages && productImages.transparent_hero_image
      ? productImages.transparent_hero_image
      : productImages && productImages.transparent_config_image
      ? productImages.transparent_config_image
      : image;
  }
  return productImages
    ? productImages.transparent_config_image_ts
      ? productImages.transparent_config_image_ts
      : productImages.transparent_config_image
    : image;
};

export default getProductImageSrc;
