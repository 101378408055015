import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Currency from 'components/consumer/Currency';
import Placeholder from 'components/consumer/Placeholder';
import VisuallyHidden from 'components/consumer/VisuallyHidden';

const ProductCardPriceUI = ({
  discountedPrice,
  finalOptionText,
  hasDiscount,
  hasTieredDiscount,
  hideOnHover,
  installmentPriceText,
  isInContentfulPromotionHero = false,
  isInStock,
  isTradeUser,
  layout,
  loading = false,
  minPrice,
  numberOfSizes,
  originalPrice,
  priceMonthlyAsLowAs,
  salePrice,
  showInstallmentPriceText = false,
  showPriceMonthlyAsLowAs = false,
  size = 'xs',
}) => {
  if (loading) {
    return (
      <Placeholder type="rect" loading style={{ height: 16, width: 172 }} />
    );
  }

  const legacyMinSalePrice =
    hasTieredDiscount && discountedPrice ? discountedPrice.max : salePrice;

  const minSalePrice = minPrice ?? legacyMinSalePrice;

  const legacyMaxSalePrice =
    !!hasTieredDiscount && !!discountedPrice
      ? discountedPrice.least
      : undefined;

  const maxSalePrice = minPrice ? salePrice : legacyMaxSalePrice;

  return (
    <>
      <div
        className={classNames(
          `text-xs font-bold opacity-100 [transition:all_.25s_ease-in-out] md:relative`,
          {
            'text-xl font-bold': size === 'xl',
            'hover:md:hidden': hideOnHover,
            '![transition:none]': isInContentfulPromotionHero,
          }
        )}
      >
        {!!hasDiscount && <VisuallyHidden>Original price:</VisuallyHidden>}

        <Currency
          className={classNames('font-bold', {
            'text-xs xl:text-sm': size === 'xs',
            'text-sm xl:text-base': size === 'sm',
            'text-base xl:text-lg': size === 'md',
            'text-lg xl:text-xl': size === 'lg',
            'text-xl xl:text-2xl': size === 'xl',
            'text-gray-light1': !hasDiscount,
            'text-gray-light2 mr-1 line-through': hasDiscount,
          })}
          data-name="top-left-product-img-price"
          data-xc="top-left-product-img-price"
        >
          {originalPrice}
        </Currency>

        {!!hasDiscount && (
          <>
            <VisuallyHidden>Price:</VisuallyHidden>

            <Currency
              className={classNames('font-bold', {
                'text-xs xl:text-sm': size === 'xs',
                'text-sm xl:text-base': size === 'sm',
                'text-base xl:text-lg': size === 'md',
                'text-lg xl:text-xl': size === 'lg',
                'text-xl xl:text-2xl': size === 'xl',
                'text-gray-light1': !hasDiscount,
                'text-red': hasDiscount,
              })}
              data-name="top-left-product-img-price"
              data-xc="top-left-product-img-price"
            >
              {minSalePrice}
            </Currency>

            {!!maxSalePrice && (
              <>
                <span className="text-red">&nbsp;-&nbsp;</span>
                <Currency
                  className={classNames('font-bold text-red', {
                    'text-xs xl:text-sm': size === 'xs',
                    'text-sm xl:text-base': size === 'sm',
                    'text-base xl:text-lg': size === 'md',
                    'text-lg xl:text-xl': size === 'lg',
                    'text-xl xl:text-2xl': size === 'xl',
                  })}
                >
                  {maxSalePrice}
                </Currency>
              </>
            )}
          </>
        )}

        {!isTradeUser && showInstallmentPriceText && layout !== 'clearance' && (
          <div
            className={classNames('text-gray', {
              'text-xs': size === 'xs',
              'text-sm': size === 'sm',
              'text-base': size === 'md',
              'text-lg': size === 'lg',
              'text-xl font-bold': size === 'xl',
            })}
          >
            {installmentPriceText}
          </div>
        )}
      </div>
      {!(layout === 'clearance' && !isInStock) ? (
        <div
          className={classNames('text-xs text-gray-light1', {
            'text-sm': size === 'sm',
            'text-base': size === 'md',
            'text-lg': size === 'lg',
            'text-xl font-bold': size === 'xl',
          })}
        >
          {showPriceMonthlyAsLowAs && (
            <>
              {!!priceMonthlyAsLowAs && (
                <span>As low as {priceMonthlyAsLowAs}/month</span>
              )}

              {!!priceMonthlyAsLowAs && !!numberOfSizes && (
                <span>&nbsp;&bull;&nbsp;</span>
              )}
            </>
          )}

          {!!numberOfSizes && <span>{numberOfSizes} sizes</span>}
        </div>
      ) : null}
      {layout !== 'minimal' && finalOptionText?.length > 0 && (
        <div
          className={classNames('text-gray font-bold', {
            'text-xs': size === 'xs',
            'text-sm': size === 'sm',
            'text-base': size === 'md',
            'text-lg': size === 'lg',
            'text-xl': size === 'xl',
          })}
        >
          {finalOptionText}
        </div>
      )}
    </>
  );
};

ProductCardPriceUI.propTypes = {
  discountedPrice: PropTypes.string,
  finalOptionText: PropTypes.string,
  hasDiscount: PropTypes.bool,
  hasTieredDiscount: PropTypes.bool,
  hideOnHover: PropTypes.bool,
  installmentPriceText: PropTypes.string,
  isInContentfulPromotionHero: PropTypes.bool,
  isInStock: PropTypes.bool,
  isTradeUser: PropTypes.bool,
  layout: PropTypes.oneOf([
    'category',
    'clearance',
    'favorite',
    'mini',
    'minimal',
  ]),
  loading: PropTypes.bool,
  minPrice: PropTypes.number,
  numberOfSizes: PropTypes.number,
  originalPrice: PropTypes.number,
  priceMonthlyAsLowAs: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  salePrice: PropTypes.number,
  showInstallmentPriceText: PropTypes.bool,
  showPriceMonthlyAsLowAs: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default ProductCardPriceUI;
