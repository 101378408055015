import React from 'react';
import PropTypes from 'prop-types';
import { Link as InternalLink } from 'react-router-dom';
import normalizeInternalLink from 'commons/normalizeInternalLink';


const Link = React.forwardRef(
  ({ to, openExternalInNewTab = true, ...props }, ref) => {
    if (!to) {
      return null;
    }

    const isNonWebLink =
      /^mailto?:/.test(to) || /^sms?:/.test(to) || /^tel?:/.test(to);

    if (isNonWebLink) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a href={to} {...props} ref={ref} />
      );
    }

    const isExternalLink = /^https?:\/\//.test(to) || /^http?:\/\//.test(to);

    if (isExternalLink) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={to}
          target={openExternalInNewTab ? '_blank' : undefined}
          {...props}
          ref={ref}
          rel="noreferrer"
        />
      );
    }

    return <InternalLink to={normalizeInternalLink(to)} {...props} ref={ref} />;
  }
);

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openExternalInNewTab: PropTypes.bool,
};

export default Link;
