export const isPDPQueryVariablesEqual = (a, b) => {
  if ((a.id && !b.id) || (!a.id && b.id) || a.id !== b.id) {
    return false;
  }
  if ((a.slug && !b.slug) || (!a.slug && b.slug) || a.slug !== b.slug) {
    return false;
  }

  return true;
};

export const getPriceDetailsUtil = (
  priceObj,
  promotion,
  optionPrice,
  isClearance
) => {
  const tiers = promotion?.tiers;
  const hasTieredDiscount = !!tiers;

  const [minDiscount, maxDiscount] =
    hasTieredDiscount && tiers?.length
      ? tiers.reduce(
          (acc, tier) => [
            Math.min(acc[0], tier.discount),
            Math.max(acc[1], tier.discount),
          ],
          [100, 0]
        )
      : [0, 0];

  const priceWithOptionsPrice = priceObj.original_price + optionPrice;

  const salePriceWithOptionsPrice = isClearance
    ? priceObj.final_price + optionPrice
    : priceObj.discount_percent
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * priceObj.discount_percent) / 100)
    : priceWithOptionsPrice;

  const leastTieredDiscountedPrice = hasTieredDiscount
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * minDiscount) / 100)
    : null;

  const maxTieredDiscountedPrice = hasTieredDiscount
    ? priceWithOptionsPrice -
      Math.round((priceWithOptionsPrice * maxDiscount) / 100)
    : null;

  const hasDiscount = salePriceWithOptionsPrice < priceWithOptionsPrice;
  return {
    hasDiscount,
    hasTieredDiscount,
    leastTieredDiscountedPrice,
    maxTieredDiscountedPrice,
    priceWithOptionsPrice,
    salePriceWithOptionsPrice,
  };
};

export const findAvailabilityAndVisibility = attributes => {
  let availability = 0;
  let visibility = 0;
  let found = 0;

  for (let i = 0; i < attributes.length; i++) {
    if (attributes[i].code === 'item_availability') {
      availability = attributes[i].value;
      found += 1;
    }

    if (attributes[i].code === 'visibility') {
      visibility = attributes[i].value;
      found += 1;
    }

    if (found >= 2) {
      break;
    }
  }

  return { availability, visibility };
};
