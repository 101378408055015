import { useMemo } from 'react';

import { convertProductOptionsToUsableFormat } from 'components/consumer/PDP/helpers/pdpOptionUtils';
import useAllOptions from 'data-hooks/useAllOptions';
import usePDPQueryOptionsForProduct from './usePDPQueryOptionsForProduct';

const usePDPGetOptionsForProduct = (
  { id, slug } = {},
  { skip = false } = {}
) => {
  // Get all options (for all products)
  const {
    loading: allOptionValuesLoading,
    allOptionsValues,
    error: allOptionValuesError,
  } = useAllOptions();

  const isAllOptionsLoading = allOptionValuesLoading && !allOptionsValues;

  // Get all options for this specific product
  const {
    loading: productOptionsLoading,
    error: productOptionsError,
    data: productOptionsData,
  } = usePDPQueryOptionsForProduct({ id, slug }, { skip });

  const productOptions = useMemo(() => {
    if (!productOptionsData?.productOptions || !allOptionsValues) {
      return null;
    }

    const finalOptions = convertProductOptionsToUsableFormat(
      productOptionsData,
      allOptionsValues
    );

    return finalOptions;
  }, [productOptionsData, allOptionsValues]);

  return {
    data: productOptions,
    loading: productOptionsLoading || isAllOptionsLoading,
    error: productOptionsError || allOptionValuesError,
  };
};

export default usePDPGetOptionsForProduct;
