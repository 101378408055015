import useSuspenseQuery from 'commons/useSuspenseQuery';
import { PRODUCT_OPTIONS } from 'data-hooks/queries';
import usePDPApolloQueryVariables from 'data-hooks/usePDPApolloQueryVariables';

const usePDPQueryOptionsForProduct = ({ id, slug }, { skip } = {}) => {
  // Get variables to send to use in productOptions query. When product ID is
  // available then we only send that in the variables, otherwise we send the
  // slug. If neither slug or ID are available, then we skip the query.
  const {
    variables: productOptionsVariables,
    skip: skipProductOptionsQuery,
  } = usePDPApolloQueryVariables(id, slug);

  // Get options for this product
  const { loading, error, data } = useSuspenseQuery(PRODUCT_OPTIONS, {
    variables: productOptionsVariables,
    skip: skip || skipProductOptionsQuery,
    context: { noBatch: true },
  });

  return {
    loading: loading && !data,
    error,
    data,
    skipProductOptionsQuery,
  };
};

export default usePDPQueryOptionsForProduct;
