const getVariantSkuForSelectedOptionsExtended = (
  selectedOptionsObject,
  optionsOrder
) => {
  if (!selectedOptionsObject) {
    return '';
  }

  return (
    optionsOrder?.reduce(
      (variantSkuAcc, optId) =>
        selectedOptionsObject[optId]?.value?.sku
          ? variantSkuAcc + selectedOptionsObject[optId].value.sku
          : variantSkuAcc,
      ''
    ) ?? ''
  );
};

export default getVariantSkuForSelectedOptionsExtended;
