import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PRODUCT_GRID_DATA_XC_ID } from 'commons/constants';
import SSRSafeElement from 'commons/SSRSafeElement';
import FixedWidthContainer from 'components/consumer/FixedWidthContainer';

const ProductCardGridUI = ({
  children,
  columnsOnDesktop = '4',
  columnsOnMobile = '2',
  limitWidth = true,
  containerRef,
}) => {
  const gridContent = (
    <div
      className={classNames('grid gap-1 md:gap-4 xl:gap-5 min-[1920px]:gap-8', {
        'lg:[grid-template-columns:1fr]': columnsOnDesktop === '1',
        'lg:[grid-template-columns:repeat(2,_minmax(0,_1fr))]':
          columnsOnDesktop === '2',
        'lg:[grid-template-columns:repeat(3,_minmax(0,_1fr))]':
          columnsOnDesktop === '3',
        'lg:[grid-template-columns:repeat(4,_minmax(0,_1fr))]':
          columnsOnDesktop === '4',
        'md:[grid-template-columns:repeat(auto-fill,_minmax(280px,_1fr))]':
          columnsOnDesktop === 'auto',
        '[grid-template-columns:1fr]': columnsOnMobile === '1',
        '[grid-template-columns:repeat(2,_minmax(0,_1fr))]':
          columnsOnMobile === '2',
      })}
      ref={containerRef}
      // Note: This data-xc attribute is not just used for testing, it is
      // necessary so that we have a stable selector we can use to find the
      // product grid and scroll to the first element when required (e.g. after
      // the user changes filters or sort order)
      data-xc={PRODUCT_GRID_DATA_XC_ID}
    >
      {children}
    </div>
  );

  if (limitWidth) {
    return (
      <FixedWidthContainer wide noMobilePadding noTabletPadding>
        {gridContent}
      </FixedWidthContainer>
    );
  }

  return gridContent;
};

ProductCardGridUI.propTypes = {
  children: PropTypes.node,
  columnsOnDesktop: PropTypes.oneOf(['1', '2', '3', '4', 'auto']),
  columnsOnMobile: PropTypes.oneOf(['1', '2']),
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(SSRSafeElement) }),
  ]),
  limitWidth: PropTypes.bool,
};

export default ProductCardGridUI;
