import { useLocation } from 'react-router-dom';

import usePDPGetStockAvailability from 'data-hooks/usePDPGetStockAvailability';
import usePDPGetBasicDetails from '../usePDPGetBasicDetails';
import usePDPCreateProductOptions from './usePDPCreateProductOptions';

const usePDPCreateContextValue = (
  {
    slug,
    isEditOrder = false,
    isQuickView = false,
    pdpIsModularBYOProduct = false,
    pdpVariantSkuOverride = null,
  } = {},
  { skip = false } = {}
) => {
  const basicDetails = usePDPGetBasicDetails({ slug }, { skip });
  const location = useLocation();
  const id = basicDetails.data?.id || location?.state?.productId || null;

  const { atoms, productOptions } = usePDPCreateProductOptions(
    {
      id,
      shouldUpdateQueryParam: !isQuickView && !isEditOrder,
      slug,
      pdpVariantSkuOverride,
    },
    { skip }
  );

  // Get stock status for product
  const stockAvailability = usePDPGetStockAvailability({
    basicDetails,
    productOptions,
  });

  return {
    atoms,
    basicDetails,
    id,
    pdpIsModularBYOProduct,
    pdpVariantSkuOverride,
    pdpIsAvailableInSizesOpen: true,
    productOptions,
    stockAvailability,
    slug,
  };
};

export default usePDPCreateContextValue;
