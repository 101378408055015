import React from 'react';
import PropTypes from 'prop-types';

const ProductCardSwatchesImage = ({ src, label = '' }) => {
  if (!src) {
    return null;
  }

  return (
    <img
      alt={label}
      height={20}
      src={`${src}?auto=format%2Ccompress&fit=crop&crop=entropy&h=20&w=20&cs=srgb`}
      width={20}
    />
  );
};

ProductCardSwatchesImage.propTypes = {
  label: PropTypes.string,
  src: PropTypes.string,
};

const ProductCardSwatchesImageMemo = React.memo(ProductCardSwatchesImage);

export default ProductCardSwatchesImageMemo;
